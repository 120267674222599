import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import persistStore from '@glass/shared/modules/store/persistStore';

export default function createStore(
  reducer,
  initialState,
  helpers,
  { subscribe, onCreateStoreAction },
) {
  const middleware = [thunk.withExtraArgument(helpers)];

  let enhancer;

  if (process.env.NODE_ENV !== 'production') {
    // https://github.com/zalmoxisus/redux-devtools-extension#14-using-in-production
    const composeEnhancers = composeWithDevTools({
      // Options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options
      name: `${process.env.BRAND}@${__BUILD__}`,
    });

    // https://redux.js.org/docs/api/applyMiddleware.html
    enhancer = composeEnhancers(applyMiddleware(...middleware));
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  const store = reduxCreateStore(reducer, initialState, enhancer);
  const persistor = persistStore(store);

  if (typeof onCreateStoreAction === 'function') {
    store.dispatch(onCreateStoreAction());
  }

  if (typeof subscribe === 'function') {
    let currentState;
    store.subscribe(() => {
      const prevState = currentState;
      currentState = store.getState();
      subscribe(prevState, currentState, store, helpers);
    });
  }

  return { store, persistor };
}
