import {
  BASE_PATH_2_QUERY,
  BASE_PATH_3_QUERY,
  PageParam,
  QueryParam,
  RESUME_ID_QUERY,
} from '@glass/common/modules/pages/queryParams';
import {
  ACCOMPLISHMENT_SECTION,
  ADDITIONAL_SECTION,
  AFFILIATION_SECTION,
  CERTIFICATIONS_SECTION,
  CHECKOUT_SECTION,
  CONTACT_SECTION,
  DOWNLOAD_SECTION,
  EDUCATION_SECTION,
  EXPERIENCE_SECTION,
  MISCELLANEOUS_SECTION,
  Section,
  SKILLS_SECTION,
  SUMMARY_SECTION,
  TEMPLATES_SECTION,
  UPGRADE_SECTION,
} from '@glass/web/modules/resumes/router/sections';
import {
  DETAILS_STEP,
  EDIT_STEP,
  INTRO_STEP,
  ROOT_STEP,
  Step,
  SUMMARY_STEP,
} from '@glass/web/modules/resumes/router/steps';

const requireResume: QueryParam[] = [RESUME_ID_QUERY];

export const metaStepData: { firstSection: Section } = {
  firstSection: TEMPLATES_SECTION,
};

export type StepData = {
  steps: Step[];
  nextSection: Section | null;
  mandatory?: boolean;
  title?: string;
  requiredKeys?: QueryParam[];
};

const CHECKOUT_TITLE = 'Checkout';
const UPGRADE_TITLE = 'Upgrade';
// todo: move to router
const stepData: Record<Section, StepData> = {
  [TEMPLATES_SECTION]: {
    steps: [ROOT_STEP],
    nextSection: CONTACT_SECTION,
  },
  [CONTACT_SECTION]: {
    steps: [ROOT_STEP],
    // mandatory: true,
    nextSection: EXPERIENCE_SECTION,
  },
  [EXPERIENCE_SECTION]: {
    steps: [INTRO_STEP, EDIT_STEP, DETAILS_STEP, SUMMARY_STEP],
    mandatory: true,
    nextSection: EDUCATION_SECTION,
    title: 'Work History',
  },
  [EDUCATION_SECTION]: {
    steps: [INTRO_STEP, EDIT_STEP, SUMMARY_STEP],
    mandatory: true,
    nextSection: SKILLS_SECTION,
  },
  [SKILLS_SECTION]: {
    steps: [INTRO_STEP, DETAILS_STEP],
    mandatory: true,
    nextSection: SUMMARY_SECTION,
  },
  [SUMMARY_SECTION]: {
    steps: [INTRO_STEP, DETAILS_STEP],
    mandatory: true,
    nextSection: MISCELLANEOUS_SECTION,
    title: 'Professional Summary',
  },
  [MISCELLANEOUS_SECTION]: {
    steps: [ROOT_STEP],
    nextSection: DOWNLOAD_SECTION,
    title: 'Miscellaneous',
  },
  [ACCOMPLISHMENT_SECTION]: {
    steps: [ROOT_STEP],
    nextSection: DOWNLOAD_SECTION,
  },
  [AFFILIATION_SECTION]: {
    steps: [ROOT_STEP],
    nextSection: DOWNLOAD_SECTION,
  },
  [CERTIFICATIONS_SECTION]: {
    steps: [ROOT_STEP],
    nextSection: DOWNLOAD_SECTION,
  },
  [ADDITIONAL_SECTION]: {
    steps: [ROOT_STEP],
    nextSection: DOWNLOAD_SECTION,
  },
  [DOWNLOAD_SECTION]: {
    steps: [INTRO_STEP, ROOT_STEP],
    nextSection: UPGRADE_SECTION,
    requiredKeys: requireResume,
  },
  [UPGRADE_SECTION]: {
    steps: [ROOT_STEP],
    nextSection: CHECKOUT_SECTION,
    requiredKeys: requireResume,
    title: UPGRADE_TITLE,
  },
  [CHECKOUT_SECTION]: {
    steps: [ROOT_STEP],
    nextSection: null,
    requiredKeys: requireResume,
    title: CHECKOUT_TITLE,
  },
};

export const isValidBuilderSection = (section?: any) => section && !!stepData[section as Section];

export type SectionStep = {
  [BASE_PATH_2_QUERY]: Section;
  [BASE_PATH_3_QUERY]?: Step;
};

export type SectionStepWithQuery = SectionStep & Partial<Record<QueryParam | PageParam, string>>;

export const isValidBuilderSectionStep = (
  {
    [BASE_PATH_2_QUERY]: section,
    [BASE_PATH_3_QUERY]: step = ROOT_STEP,
    ...otherQuery
  }: SectionStepWithQuery,
  { checkQuery = true }: { checkQuery?: boolean } = {},
) => {
  if (!isValidBuilderSection(section)) {
    return false;
  }

  const { steps, requiredKeys = [] } = stepData[section as Section];

  if (!steps.includes(step as Step)) {
    return false;
  }

  if (!checkQuery) {
    return true;
  }

  return requiredKeys.every((requiredKey) => !!otherQuery[requiredKey]);
};

export const DELIMITER = ',';

export default stepData;
