export const MOST_RECENT_RESUME_ID = 'mostRecentResumeId';
export const CREATED_AT = 'createdAt';
export const STRIPE_CUSTOMER_ID = 'stripeCustomerId';
export const STRIPE_SUBSCRIPTION_STATUS = 'stripeSubscriptionStatus';
export const STRIPE_TRIAL_END = 'stripeTrialEnd';
export const STRIPE_SUBSCRIPTION_CANCEL_AT_PERIOD_END = 'stripeSubscriptionCancelAtPeriodEnd';
export const STRIPE_SUBSCRIPTION_CANCEL_AT = 'stripeSubscriptionCancelAt';
export const STRIPE_SUBSCRIPTION_CANCELED_AT = 'stripeSubscriptionCanceledAt';
export const STRIPE_SUBSCRIPTION_PLAN_ID = 'stripeSubscriptionPlanId';
export const STRIPE_SUBSCRIPTION_PLAN_AMOUNT = 'stripeSubscriptionPlanAmount';
export const STRIPE_CURRENCY = 'stripeCurrency';
export const KLAVIYO_PRIVATE_KEY = 'pk';
export const KLAVIYO_AUTH_TOKEN = 'kat';
