import createHandleFetchResponse from '@glass/common/modules/fetch/createHandleFetchResponse';
import { API_CONFIG_PATH } from '@glass/common/modules/pages/paths';
import type { LocaleType } from '@glass/env/modules/locales/constants';
import makeOriginLocale from '@glass/env/modules/locales/makeOriginLocale';
import { currentOrigin } from '@glass/env/modules/origins/constants';

// todo: migrate to middleware
const fetchUserConfig = async ({ locale }: { locale: LocaleType }) => {
  const isBrowser = typeof window !== 'undefined';
  if (
    !isBrowser ||
    (process.env.NODE_ENV !== 'production' && !process.env.NEXT_PUBLIC_DEV_FETCH_USER_CONFIG)
  ) {
    if (process.env.NEXT_PUBLIC_DEBUG === 'user-config') {
      console.warn('skipped user config fetch on dev');
    }
    return null;
  }

  return fetch(`${makeOriginLocale(currentOrigin, locale)}${API_CONFIG_PATH}`)
    .then(createHandleFetchResponse('fetchUserConfig'))
    .then((r) => r.json());
};

export default fetchUserConfig;
