const REGEX_LEADING_WHITESPACE = /^(?:(<\/?\w+>)?( |&nbsp;))+/m;
const REGEX_TRAILING_WHITESPACE = /( |&nbsp;)(<\/?\w+>)?$/m;
const REGEX_DOUBLE_WHITESPACE = /( |&nbsp;){2,}/m;
const REGEX_DOUBLE_WITH_TAG_WHITESPACE = /( |nbsp;)+((?:<\/?\w+>)+)( |nbsp;)+/m;

const REGEXP_TRIM_DUPLICATE_SPACES = [
  REGEX_LEADING_WHITESPACE,
  REGEX_TRAILING_WHITESPACE,
  REGEX_DOUBLE_WHITESPACE,
  REGEX_DOUBLE_WITH_TAG_WHITESPACE,
];

export const isTrimAndDuplicateWhitespace = (phrase: any) =>
  REGEXP_TRIM_DUPLICATE_SPACES.some((pattern) => pattern.test(phrase));

const removeUnnecessaryWhiteSpace = (value: string) =>
  value
    .trim()
    .replace(REGEX_LEADING_WHITESPACE, '$1')
    .replace(REGEX_TRAILING_WHITESPACE, '$2')
    .replace(REGEX_DOUBLE_WHITESPACE, '$1')
    .replace(REGEX_DOUBLE_WITH_TAG_WHITESPACE, '$2$1');

export default removeUnnecessaryWhiteSpace;
