export const RESUME_DOWNLOAD_URL_VERSION = 'RESUME_DOWNLOAD_URL_VERSION';
export const HTML_PATH = 'HTML_PATH';
export const INTERNAL_PATH = 'INTERNAL_PATH';

export default {
  name: RESUME_DOWNLOAD_URL_VERSION,
  variations: [
    {
      value: HTML_PATH,
      weight: 1,
    },
    {
      value: INTERNAL_PATH,
      weight: 0,
    },
  ],
};
