import { selectIsAssignmentReady } from '@glass/common/modules/assignments/redux';
import selectUserConfigReady from '@glass/web/modules/userConfig/selectors/selectUserConfigReady';

import { RESUME_REDUCER_KEY } from '@glass/shared/modules/resumes/resumesReducer';
import { INTERESTS_REDUCER_KEY } from '@glass/shared/modules/store/constants';
import createSelectReducerRehydrated from '@glass/shared/modules/store/createSelectReducerRehydrated';

const selectInterestReady = createSelectReducerRehydrated(INTERESTS_REDUCER_KEY);
const selectResumeReady = createSelectReducerRehydrated(RESUME_REDUCER_KEY);

const selectReady = (state) => {
  if (process.env.NEXT_PUBLIC_BRAND === 'engine') {
    return selectUserConfigReady(state);
  }

  return (
    selectIsAssignmentReady(state) &&
    selectUserConfigReady(state) &&
    selectInterestReady(state) &&
    selectResumeReady(state)
  );
};

export default selectReady;
