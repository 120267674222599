import isValue from '@glass/common/modules/utils/isValue';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';

export const reFileExtension = /(?:\.([^.]+))?$/;

const EQUAL_DELIMITER = '=';
const SETTING_DELIMITER = '&';

export const parseOptionsSlug = (
  slug,
  { settingsDelimiter = SETTING_DELIMITER, equalDelimiter = EQUAL_DELIMITER } = {},
) =>
  slug.split(settingsDelimiter).reduce((parsed, kv) => {
    const [key, value] = kv.split(equalDelimiter);
    if (key && value) {
      return {
        ...parsed,
        [decodeURIComponent(key)]: decodeURIComponent(value),
      };
    }
    return parsed;
  }, {});

export const createOptionsSlug = (
  options,
  { settingsDelimiter = SETTING_DELIMITER, equalDelimiter = EQUAL_DELIMITER } = {},
) =>
  Object.keys(removeEmptyKeys(options))
    .sort()
    .reduce((slug, key) => {
      const val = options[key];
      if (isValue(key) && isValue(val)) {
        return `${slug}${slug ? settingsDelimiter : ''}${encodeURIComponent(
          key,
        )}${equalDelimiter}${encodeURIComponent(val)}`;
      }
      return slug;
    }, '')
    .toLowerCase();
