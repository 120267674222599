import mergeArraysByKey from '@glass/shared/helpers/utils/mergeArraysByKey';
import { INDUSTRIES_KEY, JOB_TITLES_KEY } from '@glass/shared/modules/store/constants';
import createCustomPersistMerge from '@glass/shared/modules/store/createCustomPersistMerge';

const customMerge = (key, a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    switch (key) {
      case JOB_TITLES_KEY:
      case INDUSTRIES_KEY:
        return mergeArraysByKey(a, b, 'slug');
      default:
        return a.concat(b);
    }
  }

  return undefined;
};

const defaultStateReconciler = createCustomPersistMerge(customMerge);

export default defaultStateReconciler;
