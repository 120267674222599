import { SET_USER } from '@glass/web/modules/auth';

import cleanTypename from '@glass/shared/helpers/cleanTypename';

const setUserAuth = ({ user, token }) => ({
  type: SET_USER,
  payload: {
    user: cleanTypename(user),
    token,
  },
});

export default setUserAuth;
