/**
 * Checks if a cookie name is a valid key in the cookieConfigs object.
 * @param {string} name - The name of the cookie to be validated.
 * @param {Record<string, any>} cookieConfigs - An object containing configurations for cookies.
 * @returns {boolean} - Returns true if the cookie name is a key in the cookieConfigs object, otherwise returns false.
 */
const isValidCookieName = (name: string, cookieConfigs: Record<string, any>) =>
  !!cookieConfigs[name];

export default isValidCookieName;
