export const USER_CONFIG_REDUCER_KEY = 'userConfig';

type Names = {
  en: string;
};

type GeoCity = {
  code: string;
  geonameId: number;
  names: Names;
};

type GeoLocation = {
  latitude: number;
  longitude: number;
  timeZone: string;
  metroCode: number;
  accuracyRadius: number;
};

type GeoPostal = {
  code: string;
};

type GeoCountry = {
  geonameId: number;
  names: Names;
  isoCode: string;
};

type GeoSubdivisions = {
  geonameId: number;
  names: Names;
  isoCode: string;
};

type UserConfigGeo = {
  city: GeoCity;
  location: GeoLocation;
  postal: GeoPostal;
  subdivisions: GeoSubdivisions[];
  country: GeoCountry;
};

export type UserConfigState = {
  internal?: boolean;
  asyncReady: boolean;
  assignmentId?: string;
  deviceId?: string;
  bot: string | boolean;
  geo: UserConfigGeo;
  authReady: boolean;
};
