import createTrackingProps from '@glass/common/modules/tracking/createTrackingProps';

const getKlaviyoEventProperties = (props) => {
  return {
    ...(props ? createTrackingProps(props) : null),
    $event_id: props?.eventId,
    $value: props?.value,
  };
};

export default getKlaviyoEventProperties;
