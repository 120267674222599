export const CHEETAH_SELECTION = 'CHEETAH_SELECTION';
export const USE_LOCAL_CHEETAH = 'USE_LOCAL_CHEETAH';
export const USE_REMOTE_CHEETAH_NO_PROXY = 'USE_REMOTE_CHEETAH_NO_PROXY';

export default {
  name: CHEETAH_SELECTION,
  version: 2,
  variations: [
    {
      value: USE_LOCAL_CHEETAH,
      weight: 0,
    },
    {
      value: USE_REMOTE_CHEETAH_NO_PROXY,
      weight: 1,
    },
  ],
};
