import vercelEnvVars from '@glass/web/modules/environment/vercelEnvVars';
import { SET_USER_CONFIG } from '@glass/web/modules/userConfig/actions/setUserConfigAction';

export const USER_CONFIG_REDUCER_KEY = 'userConfig';

const initialState = {
  clientDeployment: process.env.NEXT_PUBLIC_VERCEL_URL,
  clientBuildId: __BUILD__,
  ...vercelEnvVars,
  geo: {},
};

export default function userConfig(state = initialState, action) {
  switch (action.type) {
    case SET_USER_CONFIG:
      return {
        ...state,
        ...action.payload.userConfig,
        geo: {
          ...state.geo,
          ...action.payload.userConfig.geo,
        },
      };
    default:
      return state;
  }
}
