export const LOCALE_EN = 'en';
export const LOCALE_EN_CA = 'en-CA';
export const LOCALE_FR_CA = 'fr-CA';
export const LOCALE_EN_US = 'en-US';

export type LocaleType =
  | typeof LOCALE_EN
  | typeof LOCALE_EN_CA
  | typeof LOCALE_FR_CA
  | typeof LOCALE_EN_US;

// todo: get rid of "en"
export const ALL_LOCALES: LocaleType[] = [LOCALE_EN, LOCALE_EN_US, LOCALE_EN_CA, LOCALE_FR_CA];

export const DEFAULT_LOCALE = LOCALE_EN as LocaleType;
