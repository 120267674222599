import { DEFAULT_ADJECTIVE_WORDS } from '@glass/common/modules/content/staticVariations/adjectiveWords/adjectiveWordVariations';
import { DEFAULT_RESUME_WORDS } from '@glass/common/modules/content/staticVariations/resumeWords/resumeWordVariations';
import { DEFAULT_RESUME_BUILDER_BASE_PATH } from '@glass/common/modules/pages/paths';
import { SET_RESUME_BUILDER_BASE_PATH } from '@glass/web/modules/content/actions/setResumeBuilderBasePath';

import { DEFAULT_CONTENT_WORDS } from '@glass/shared/modules/content/staticVariations/contentWords/contentWordVariations';
import { DEFAULT_VERB_WORDS } from '@glass/shared/modules/content/staticVariations/verbWords/verbWordVariations';
import persistReducer from '@glass/shared/modules/store/persistReducer';

export const CONTENT_REDUCER_KEY = 'content';
export const SET_DEFAULT_WORD = 'SET_DEFAULT_WORD';
export const SET_BROWSE_BASE_PATH = 'SET_BROWSE_BASE_PATH';

export const RESUME_CONTENT_KEY = 'resumeWord';
export const ADJECTIVE_CONTENT_KEY = 'adjectiveWord';
export const VERB_CONTENT_KEY = 'verbWord';
export const CONTENT_CONTENT_KEY = 'contentWord';

const initialState = {
  contentWords: DEFAULT_CONTENT_WORDS,
  adjectiveWords: DEFAULT_ADJECTIVE_WORDS,
  verbWords: DEFAULT_VERB_WORDS,
  resumeWords: DEFAULT_RESUME_WORDS,
  resumeBuilderBasePath: DEFAULT_RESUME_BUILDER_BASE_PATH,
  _set: false,
};

const createReducerKey = (key) => key && `${key}s`;

const persistConfig = {
  key: CONTENT_REDUCER_KEY,
  version: 2,
};

const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_WORD:
      return {
        ...state,
        [action.payload.key]: action.payload.defaultWords,
        set: true,
      };
    case SET_RESUME_BUILDER_BASE_PATH:
      return {
        ...state,
        resumeBuilderBasePath: action.payload.resumeBuilderBasePath ?? state.resumeBuilderBasePath,
      };
    case SET_BROWSE_BASE_PATH:
      return {
        ...state,
        basePath1: action.payload.basePath1 ?? state.basePath1,
      };
    default:
      return state;
  }
};

export const setDefaultWord = (key, defaultWords) => ({
  type: SET_DEFAULT_WORD,
  payload: {
    key: createReducerKey(key),
    defaultWords,
  },
});

export const createSelectDefaultWords = (key) => {
  const wordKey = createReducerKey(key);
  return (state) => state[CONTENT_REDUCER_KEY]?.[wordKey];
};

export const selectResumeWords = createSelectDefaultWords(RESUME_CONTENT_KEY);
export const selectContentWords = createSelectDefaultWords(CONTENT_CONTENT_KEY);
export const selectAdjectiveWords = createSelectDefaultWords(ADJECTIVE_CONTENT_KEY);
export const selectVerbWords = createSelectDefaultWords(VERB_CONTENT_KEY);

// eslint-disable-next-line no-underscore-dangle
export const selectContentSet = (state) => state[CONTENT_REDUCER_KEY]?._set;

export const selectResumeBuilderBasePath = (state) =>
  state[CONTENT_REDUCER_KEY]?.resumeBuilderBasePath;

export default persistReducer(persistConfig, contentReducer);
