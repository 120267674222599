import createShortenedIntervalString from '@glass/common/modules/payments/createShortenedIntervalString';
import {
  TRIAL_DAYS_PREFIX,
  INITIAL_COST_PREFIX,
  RECURRING_COST_PREFIX,
} from '@glass/common/modules/payments/prices/purchaseIdPrefixes';
import { PricingPlanType } from '@glass/common/modules/payments/types';
import { PERIOD_DELIMITER } from '@glass/common/modules/strings/constants';
import filterJoin from '@glass/common/modules/strings/filterJoin';

// - validate that initial prices and recurring prices are in the same currency and both exist
const createPurchaseId = (
  currency: string,
  // _check type
  plan: PricingPlanType,
): string => {
  if (!currency) {
    throw new Error('Currency is required');
  }

  if (!plan) {
    throw new Error('Plan is required');
  }

  const { trialPeriodDays, initialAmount, recurringAmount, interval, intervalCount } = plan;

  return filterJoin(
    [
      currency,
      trialPeriodDays ? `${TRIAL_DAYS_PREFIX}${trialPeriodDays}` : null,
      initialAmount ? `${INITIAL_COST_PREFIX}${initialAmount}` : null,
      recurringAmount ? `${RECURRING_COST_PREFIX}${recurringAmount}` : null,
      createShortenedIntervalString({
        interval,
        intervalCount,
      }),
    ],
    PERIOD_DELIMITER,
  );
};

export default createPurchaseId;
