import findState from '@glass/common/modules/geo/findState';
import getResumeContactCountryCode from '@glass/common/modules/resumes/selectors/getResumeContactCountryCode';
import getResumeContactState from '@glass/common/modules/resumes/selectors/getResumeContactState';
import type { ResumeType } from '@glass/common/modules/resumes/types';

const getResumeContactStateCode = (resume: ResumeType) => {
  const countryCode = getResumeContactCountryCode(resume);
  return findState(getResumeContactState(resume), countryCode)?.code;
};

export default getResumeContactStateCode;
