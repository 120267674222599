import isPlainObject from 'lodash/isPlainObject';

import { PERIOD_DELIMITER } from '@glass/common/modules/strings/constants';
import filterJoin from '@glass/common/modules/strings/filterJoin';
import makeArray from '@glass/common/modules/utils/makeArray';

export const generateKey = (...args: string[]) => filterJoin(args, PERIOD_DELIMITER);

type OptionsType = {
  keyPrefix?: string;
  getIndexKey?: (itm: unknown, idx: number) => string;
};

const modifyObjectKeyValues = (
  obj: unknown,
  fn: (key: string, value: unknown, options?: OptionsType) => string | [string, unknown],
  options: OptionsType = {},
) => {
  const { keyPrefix = '', getIndexKey } = options;

  const results: Record<string, unknown> = {};

  if (!isPlainObject(obj)) {
    return obj;
  }

  const plainObject = obj as Record<string, unknown>;

  Object.keys(plainObject).forEach((key) => {
    const value = plainObject[key];
    const [newKey = key, modifiedValue] = makeArray(fn(key, value, { keyPrefix, getIndexKey }));

    if (typeof newKey !== 'string') {
      return;
    }

    let newValue = typeof modifiedValue === 'undefined' ? value : modifiedValue;
    if (Array.isArray(newValue)) {
      newValue = newValue.map((itm, idx) =>
        isPlainObject(itm)
          ? modifyObjectKeyValues(itm as Record<string, unknown>, fn, {
              ...options,
              keyPrefix: generateKey(
                keyPrefix,
                newKey,
                (typeof getIndexKey === 'function' ? getIndexKey(itm, idx) : idx).toString(),
              ),
            })
          : itm,
      );
    } else if (isPlainObject(value)) {
      newValue = modifyObjectKeyValues(value as Record<string, unknown>, fn, {
        ...options,
        keyPrefix: generateKey(keyPrefix, newKey),
      });
    }
    results[newKey] = newValue;
  });

  return results;
};

export default modifyObjectKeyValues;
