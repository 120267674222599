import {
  INTERVAL_SUFFIXES,
  IntervalCountType,
  IntervalMapType,
} from '@glass/common/modules/payments/prices/pricingIntervals';

// returns 4w, m, y etc
const createShortenedIntervalString = ({
  interval,
  intervalCount,
}: {
  interval?: unknown;
  intervalCount?: unknown;
}): IntervalCountType => {
  if (
    typeof interval !== 'string' ||
    !interval[0] ||
    !INTERVAL_SUFFIXES.includes(interval[0] as IntervalMapType)
  ) {
    throw new Error(`Invalid interval: ${interval as string}`);
  }

  const intervalSuffix = interval[0] as IntervalMapType;

  if (typeof intervalCount !== 'number' || intervalCount <= 0) {
    throw new Error(`Invalid interval count: ${intervalCount as number}`);
  }

  if (intervalCount === 1) {
    return intervalSuffix as IntervalCountType;
  }

  return `${intervalCount}${intervalSuffix}` as IntervalCountType;
};

export default createShortenedIntervalString;
