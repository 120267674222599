import createAssignments, {
  ASSIGNMENT_PREFIX,
} from '@glass/common/modules/assignments/createAssignments';
import {
  AssignmentConfig,
  AssignmentContext,
  AssignmentVariationValue,
} from '@glass/common/modules/assignments/types';
import { LOW_DASH_STRING } from '@glass/common/modules/strings/constants';

export const assignmentKeyRegex = new RegExp(`^${ASSIGNMENT_PREFIX}\\S+${LOW_DASH_STRING}\\d$`);

export const createAssignmentTrackingKey =
  (prefix: string = ASSIGNMENT_PREFIX) =>
  ({ name, version }: AssignmentConfig) =>
    `${prefix}${name}${LOW_DASH_STRING}${version}`;

const createAssignmentTrackingObject = (
  configs: AssignmentConfig[],
  assignmentId: string,
  {
    prefix,
    contexts,
    overrides,
  }: {
    prefix?: string;
    contexts?: AssignmentContext[];
    overrides?: Record<string, AssignmentVariationValue>;
  } = {},
) =>
  createAssignments(configs, assignmentId, {
    contexts,
    createAssignmentKey: createAssignmentTrackingKey(prefix),
    overrides,
  });

export default createAssignmentTrackingObject;
