import type { ResumeType } from '@glass/common/modules/resumes/types';
import isValue from '@glass/common/modules/utils/isValue';
import {
  EXPERIENCES_FIELD_V2,
  RESUME_V2_FIELD,
  TITLE_FIELD_V2,
} from '@glass/web/modules/resumes/fieldKeys';

const getResumeExperienceTitles = (resume: ResumeType): string[] | undefined =>
  resume?.[RESUME_V2_FIELD]?.[EXPERIENCES_FIELD_V2]?.map?.(
    (experience) => experience?.[TITLE_FIELD_V2],
  ).filter(isValue) as string[] | undefined;

export default getResumeExperienceTitles;
