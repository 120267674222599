type KeyMatch = ((key: string, value: unknown) => boolean) | RegExp;

const isMatch = (keyMatch: KeyMatch, valueMatch?: RegExp) => (key: string, value: unknown) =>
  (typeof keyMatch === 'function' && keyMatch(key, value)) ||
  (keyMatch instanceof RegExp && !!key.match(keyMatch)) ||
  (Array.isArray(keyMatch) && keyMatch.includes(key)) ||
  (valueMatch instanceof RegExp && typeof value === 'string' && !!value.match(valueMatch));

// TODO: We are not using valueMatch in any implementation, can we remove it?
const filterObject = (
  obj: Record<string, unknown>,
  keyMatch: KeyMatch,
  valueMatch?: RegExp,
): [Record<string, unknown> | null, Record<string, unknown> | null] => {
  if (!obj || !keyMatch) {
    throw new Error('no filtering possible');
  }

  const matchingFn = isMatch(keyMatch, valueMatch);

  return Object.keys(obj).reduce(
    ([yes, no], key) => {
      const value = obj[key];
      return matchingFn(key, value)
        ? [
            {
              ...yes,
              [key]: obj[key],
            },
            no,
          ]
        : [
            yes,
            {
              ...no,
              [key]: obj[key],
            },
          ];
    },
    [null, null] as [Record<string, unknown> | null, Record<string, unknown> | null],
  );
};

export default filterObject;
