import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import apolloLogger from 'apollo-link-logger';

import { ONE_SEC_MS } from '@glass/common/modules/dates/constants';
import { BUILD_VERSION_HEADER, LOCALE_HEADER } from '@glass/common/modules/pages/headers';
import { API_GRAPHQL_PATH } from '@glass/common/modules/pages/paths';
import getLocalePath from '@glass/env/modules/locales/getLocalePath';
import makeOriginLocale from '@glass/env/modules/locales/makeOriginLocale';
import { currentOrigin } from '@glass/env/modules/origins/constants';

import ApolloLinkTimeout from '@glass/shared/modules/apollo/ApolloLinkTimeout';
import createHttpLink from '@glass/shared/modules/apollo/createHttpLink';
import createPersistedQueryLink from '@glass/shared/modules/apollo/createPersistedQueryLink';
import onErrorLink from '@glass/shared/modules/apollo/onErrorLink';
import trackingLink from '@glass/shared/modules/apollo/trackingLink';
import { INTERNAL_AUTHORIZATION_JWT_HEADER } from '@glass/shared/modules/auth/headers';

const apolloClients = {};

/**
 * Creates an apollo client.
 * @param  {import('@apollo/client').NormalizedCacheObject} initialState
 * @param  {import('next').NextPageContext} ctx
 * @returns {import('@apollo/client').ApolloClient<import('@apollo/client').NormalizedCacheObject>}
 */

const create = (initialState, ctx, { timeout = 30 * ONE_SEC_MS, i18nConfig, url }) => {
  const { req } = ctx;
  // Get the locale from the context, depends on the initialization: server-side or client-side
  const locale = ctx?.locale || ctx?.router?.locale;

  const links = [
    ...(__DEV__ ? [apolloLogger] : []),
    new ApolloLinkTimeout(timeout),
    onErrorLink,
    new RetryLink(),
    trackingLink,
  ];

  const { cookie, authorization } = req?.headers || {};
  const internalAuthJwtHeader = req?.headers?.[INTERNAL_AUTHORIZATION_JWT_HEADER];

  const headers = {};
  if (__SERVER__) {
    if (cookie) {
      headers.cookie = cookie;
    }
    if (authorization) {
      headers.authorization = authorization;
    }
    if (internalAuthJwtHeader) {
      headers[INTERNAL_AUTHORIZATION_JWT_HEADER] = internalAuthJwtHeader;
    }
    headers['User-Agent'] = 'rocket-resume.com/1.0';
  }
  // Is possible to initialize a client with context=undefined, see 'useTaxonomyClient'
  // so we need to check if the locale is defined to set the header
  if (locale) {
    headers[LOCALE_HEADER] = locale;
  }
  if (__BUILD__) {
    headers[BUILD_VERSION_HEADER] = __BUILD__;
  }

  // Compute the locale path only on vercel deployments, as api paths with locale prefix don't work on localhost.

  const apiPath = __DEV__ ? API_GRAPHQL_PATH : getLocalePath(i18nConfig, API_GRAPHQL_PATH, locale);
  const uri = url || `${makeOriginLocale(currentOrigin, locale)}${apiPath}`;

  let httpLink = createHttpLink({ uri, headers });

  if (process.env.NEXT_PUBLIC_APOLLO_PERSIST_QUERIES) {
    httpLink = createPersistedQueryLink().concat(httpLink);
  }

  links.push(httpLink);

  const link = ApolloLink.from(links);

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    connectToDevTools: __DEV__ && __BROWSER__,
    ssrMode: __SERVER__, // Disables forceFetch on the server (so queries are only run once)
    link,
    cache: new InMemoryCache().restore(initialState),
    queryDeduplication: true,
    // ...options,
  });
};

/**
 * Creates an apollo client.
 * @param  {import('@apollo/client').NormalizedCacheObject} initialState
 * @param  {import('next').NextPageContext} ctx
 * @returns {import('@apollo/client').ApolloClient<import('@apollo/client').NormalizedCacheObject>}
 */
const createApolloClient = (initialState = {}, ctx = {}, options = {}) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (__SERVER__) {
    return create(initialState, ctx, options);
  }

  // Reuse client on the client-side
  let apolloClient = apolloClients[options.id || 'defaultClient']; // If the client id is not specified, use the default one
  if (!apolloClient) {
    apolloClient = create(initialState, ctx, options);
    apolloClients[options.id] = apolloClient;
  }

  return apolloClient;
};

export default createApolloClient;
