export const YEAR = 'year';
export const MONTH = 'month';
export const WEEK = 'week';
export const DAY = 'day';
export const INTERVAL_YEAR_SUFFIX = 'y';
export const INTERVAL_DAY_SUFFIX = 'd';
export const INTERVAL_MONTH_SUFFIX = 'm';
export const INTERVAL_WEEK_SUFFIX = 'w';

export type IntervalMapType =
  | typeof INTERVAL_MONTH_SUFFIX
  | typeof INTERVAL_WEEK_SUFFIX
  | typeof INTERVAL_YEAR_SUFFIX
  | typeof INTERVAL_DAY_SUFFIX;

export const INTERVAL_SUFFIXES: IntervalMapType[] = [
  INTERVAL_YEAR_SUFFIX,
  INTERVAL_MONTH_SUFFIX,
  INTERVAL_WEEK_SUFFIX,
  INTERVAL_DAY_SUFFIX,
];

export type IntervalType = typeof YEAR | typeof MONTH | typeof WEEK | typeof DAY;
export type IntervalCountType = '4w' | 'y';

export const shorthandIntervalMap: Record<IntervalMapType, IntervalType> = {
  [INTERVAL_MONTH_SUFFIX]: MONTH,
  [INTERVAL_WEEK_SUFFIX]: WEEK,
  [INTERVAL_DAY_SUFFIX]: DAY,
  [INTERVAL_YEAR_SUFFIX]: YEAR,
};
