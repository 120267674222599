import parseSearchQuery, {
  type ParseSearchQueryOptions,
} from '@glass/common/modules/url/parseSearchQuery';

const parseBrowserQuery = (options?: ParseSearchQueryOptions) => {
  if (typeof window === 'undefined' || !window?.location?.search) {
    return null;
  }

  return parseSearchQuery(window?.location?.search, options);
};

export default parseBrowserQuery;
