import makeArray from '@glass/common/modules/utils/makeArray';

const removeObjKeys = (obj: any, keys: any) => {
  if (!keys || !obj) {
    return obj;
  }
  // make it an array
  const omitKey = (key: any, value: any) => (makeArray(keys).includes(key) ? undefined : value);
  return JSON.parse(JSON.stringify(obj), omitKey);
};

export default removeObjKeys;
