import { selectAppLocale } from '@glass/web/modules/app';
import setUserConfigAction from '@glass/web/modules/userConfig/actions/setUserConfigAction';
import fetchUserConfig from '@glass/web/modules/userConfig/fetchUserConfig';

const initAsyncUserConfigAction =
  () =>
  async (dispatch, getState, { tracking }) =>
    fetchUserConfig({ locale: selectAppLocale(getState()) })
      .then((userConfig) =>
        dispatch(setUserConfigAction({ ...(userConfig || {}), asyncReady: true })),
      )
      .catch(tracking.exception);

export default initAsyncUserConfigAction;
