/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/nextjs';

import {
  selectIsAssignmentReady,
  selectAssignmentTracking,
  selectAssignmentOverrides,
} from '@glass/common/modules/assignments/redux';
import { ONE_SEC_MS } from '@glass/common/modules/dates/constants';
import createId from '@glass/common/modules/id/createId';
import { RESUME_HTML_PATHNAME } from '@glass/common/modules/pages/paths';
import {
  FACEBOOK_CLICK_ID_QUERY,
  PRESENTATION_QUERY,
  RESUME_ID_QUERY,
} from '@glass/common/modules/pages/queryParams';
import parseDisplayName from '@glass/common/modules/resumes/parseDisplayName';
import { PERIOD_DELIMITER, SPACE_STRING } from '@glass/common/modules/strings/constants';
import filterJoin from '@glass/common/modules/strings/filterJoin';
import parseCsvString from '@glass/common/modules/strings/parseCsvString';
import * as TRACKING_ACTIONS from '@glass/common/modules/tracking/constants/actions';
import * as TRACKING_CONTENT_GROUP_TYPES from '@glass/common/modules/tracking/constants/contentGroupTypes';
import * as TRACKING_DIMENSION_KEYS from '@glass/common/modules/tracking/constants/dimensionKeys';
import * as TRACKING_DIMENSION_VALUES from '@glass/common/modules/tracking/constants/dimensionValues';
import * as TRACKING_EVENTS from '@glass/common/modules/tracking/constants/events';
import * as TRACKING_LABELS from '@glass/common/modules/tracking/constants/labels';
import { ERROR_LEVEL, FATAL_LEVEL } from '@glass/common/modules/tracking/constants/levels';
import { MOST_RECENT_RESUME_ID } from '@glass/common/modules/tracking/constants/propertyKeys';
import createTrackingProps from '@glass/common/modules/tracking/createTrackingProps';
import createSlug from '@glass/common/modules/url/createSlug';
import filterObject from '@glass/common/modules/utils/filterObject';
import isValue from '@glass/common/modules/utils/isValue';
import makeArray from '@glass/common/modules/utils/makeArray';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';
import { currentOrigin } from '@glass/env/modules/origins/constants';
import { WINDOW_REDUX_KEY } from '@glass/web/components/App/constants';
import { selectUser } from '@glass/web/modules/auth';
import {
  FB_USER_CLICK_COOKIE_NAME,
  SUBSCRIPTION_TRACKING_COOKIE_NAME,
} from '@glass/web/modules/cookies/names';
import vercelEnvVars from '@glass/web/modules/environment/vercelEnvVars';
import setupRequestIdleCallbackShim from '@glass/web/modules/loading/setupRequestIdleCallbackShim';
import generateSectionStepKey from '@glass/web/modules/resumes/router/generateSectionStepKey';
import { selectResumeValue } from '@glass/web/modules/resumes/selectors';
import parseBrowserQuery from '@glass/web/modules/url/parseBrowserQuery';
import setUserConfigAction from '@glass/web/modules/userConfig/actions/setUserConfigAction';
import selectUserConfig from '@glass/web/modules/userConfig/selectors/selectUserConfig';

import trackEventMutation from '@glass/shared/modules/events/trackEvent.graphql';
import { selectNormalizedInterests } from '@glass/shared/modules/interests/interestsReducer';
import { selectMostRecentResumeId } from '@glass/shared/modules/resumes/resumesReducer';
import createSelectNextResumeBuilderParams from '@glass/shared/modules/resumes/selectors/createSelectNextResumeBuilderParams';
import TRACKING_API_METHODS, {
  DEFAULT_ANONYMOUS_SAMPLE_RATE,
  DEFAULT_NAMED_SAMPLE_RATE,
  EXCESSIVE_DURATION,
} from '@glass/shared/modules/tracking/constants/apiMethods';
import getKlaviyoEventName from '@glass/shared/modules/tracking/klaviyo/getKlaviyoEventName';
import getKlaviyoEventProperties from '@glass/shared/modules/tracking/klaviyo/getKlaviyoEventProperties';
import getKlaviyoIdentifyProperties, {
  isKlaviyoIdentifiable,
} from '@glass/shared/modules/tracking/klaviyo/getKlaviyoIdentifyProperties';
import createSelectUserStandardizedPhone from '@glass/shared/modules/userConfig/selectors/createSelectUserStandardizedPhone';
import selectReady from '@glass/shared/modules/userConfig/selectors/selectReady';
import selectUserCity from '@glass/shared/modules/userConfig/selectors/selectUserCity';
import selectUserContactEmail from '@glass/shared/modules/userConfig/selectors/selectUserContactEmail';
import selectUserCountryCode from '@glass/shared/modules/userConfig/selectors/selectUserCountryCode';
import selectUserDisplayName from '@glass/shared/modules/userConfig/selectors/selectUserDisplayName';
import selectUserFirstName from '@glass/shared/modules/userConfig/selectors/selectUserFirstName';
import selectUserLastName from '@glass/shared/modules/userConfig/selectors/selectUserLastName';
import selectUserPostalCode from '@glass/shared/modules/userConfig/selectors/selectUserPostalCode';
import selectUserStateCode from '@glass/shared/modules/userConfig/selectors/selectUserStateCode';
import getScreen from '@glass/shared/modules/window/screen';
import zendeskClient from '@glass/shared/modules/zendesk/zendeskClient';

let mixpanel;

const IS_MIXPANEL = !!(__BROWSER__ && process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);

setupRequestIdleCallbackShim();

if (IS_MIXPANEL) {
  // eslint-disable-next-line global-require
  mixpanel = require('mixpanel-browser');
}

const SESSION_QUERY_PREFIX = 'session_query_';
const EVENT_QUERY_PREFIX = 'event_query_';

const getTime = () => new Date().toISOString();

const DEFAULT_TEXT = 'default';

// todo: make these work on server?
const getPagePath = () => __BROWSER__ && window?.location?.pathname;
const getPageUrl = () => __BROWSER__ && window?.location?.href;

const router = __BROWSER__ ? require('next/router')?.default : {};

const IS_BROWSER_HTTPS =
  !process.env.NODE_ENV !== 'production' && __BROWSER__ && window?.location?.protocol === 'https:';

const DISABLED_PATHS = [RESUME_HTML_PATHNAME];
const DISABLED_QUERY_PARAMS = [PRESENTATION_QUERY];

const isDisabledQueryParam = () => {
  if (!__BROWSER__) {
    return false;
  }
  const queryParams = parseBrowserQuery();
  return queryParams && DISABLED_QUERY_PARAMS.some((qp) => !!queryParams?.[qp]);
};

const IS_DISABLED_TRACKING =
  process.env.NODE_ENV === 'production'
    ? !!process.env.CI ||
      (__BROWSER__ &&
        (!IS_BROWSER_HTTPS || DISABLED_PATHS.includes(getPagePath()) || isDisabledQueryParam()))
    : !!process.env.NEXT_PUBLIC_DEV_DISABLE_TRACKING;

class Tracking {
  constructor({ defaultProps, _contexts, _parent } = {}) {
    this._catchError = this._catchError.bind(this);
    this._forwardToParent = this._forwardToParent.bind(this);
    this._preventRunningOnChild = this._preventRunningOnChild.bind(this);
    this.createChild = this.createChild.bind(this);

    this._initLibrary = this._initLibrary.bind(this);
    this.initSentry = this.initSentry.bind(this);
    this.initTracking = this.initTracking.bind(this);
    this.initMixpanel = this.initMixpanel.bind(this);
    this.initGoogle = this.initGoogle.bind(this);
    this.initZendesk = this.initZendesk.bind(this);
    this.initFacebook = this.initFacebook.bind(this);
    this.initKlaviyo = this.initKlaviyo.bind(this);

    this.identifyZendeskUser = this.identifyZendeskUser.bind(this);
    this.identifyKlaviyoUser = this.identifyKlaviyoUser.bind(this);

    this.addDefaultProp = this.addDefaultProp.bind(this);
    this.addDefaultProps = this.addDefaultProps.bind(this);
    this.addPathProp = this.addPathProp.bind(this);

    this._childProps = this._childProps.bind(this);
    this._sendTimeProps = this._sendTimeProps.bind(this);
    this._eventTimeProps = this._eventTimeProps.bind(this);
    this._trackingProps = this._trackingProps.bind(this);
    this.registerDispatch = this.registerDispatch.bind(this);
    this.registerGetState = this.registerGetState.bind(this);
    this.registerUser = this.registerUser.bind(this);

    this.disable = this.disable.bind(this);
    this.getDisabled = this.getDisabled.bind(this);

    this.track = this.track.bind(this);
    this.trackKlaviyo = this.trackKlaviyo.bind(this);
    this.trackGa = this.trackGa.bind(this);
    this.trackGtm = this.trackGtm.bind(this);
    this.trackFb = this.trackFb.bind(this);

    this.createSubscription = this.createSubscription.bind(this);

    this.isKlaviyoServerEvent = this.isKlaviyoServerEvent.bind(this);
    this.schedulePendingEvents = this.schedulePendingEvents.bind(this);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.processPendingAnalyticsEvents = this.processPendingAnalyticsEvents.bind(this);
    this.isReady = this.isReady.bind(this);
    this._sendTrack = this._sendTrack.bind(this);
    this.trackInternal = this.trackInternal.bind(this);

    this.parent = _parent;
    this.contexts = _contexts;

    // don't initialize new tracking if there's a parent
    if (this.parent) {
      return;
    }

    this.defaultProps = { build: __BUILD__, currentOrigin };
    this.pathProps = {};
    this.getState = null;
    this.dispatch = null;
    this.user = null;
    this.queue = [];
    this.ready = __SERVER__;
    this.disabled = IS_DISABLED_TRACKING;

    if (IS_DISABLED_TRACKING) {
      if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
        console.info('tracking disabled');
        console.info('IS_BROWSER_HTTPS', IS_BROWSER_HTTPS);
        console.info(
          'DISABLED_PATHS.includes(getPagePath())',
          DISABLED_PATHS.includes(getPagePath()),
        );
        console.info('isDisabledQueryParam()', isDisabledQueryParam());
      }
    }

    this.forceReady = false;
    this.requestIdleCallbackId = null;
    this.scheduledInterval = null;
    this.fbInitialized = false;

    if (defaultProps) {
      this.addDefaultProps(defaultProps);
    }

    if (__BROWSER__) {
      this.forceReadyTimeout = setTimeout(() => {
        if (!this.isReady()) {
          this.forceReady = true;
        }
        this.schedulePendingEvents();
      }, 10 * ONE_SEC_MS);
    }

    this.initSentry();
    this.initTracking();
    this.initMixpanel();
    this.initGoogle();
    this.initKlaviyo();
    // don't initZendesk or initfacebook
  }

  static EVENTS = TRACKING_EVENTS;

  static ACTIONS = TRACKING_ACTIONS;

  static FB_EVENTS = {
    PAGE_VIEW: 'PageView',
    START_TRIAL: 'StartTrial',
    ADD_PAYMENT_INFO: 'AddPaymentInfo',
    COMPLETE_REGISTRATION: 'CompleteRegistration',
    CONTACT: 'Contact',
    INITIATE_CHECKOUT: 'InitiateCheckout',
  };

  static CONTENT_GROUPS_TYPES = TRACKING_CONTENT_GROUP_TYPES;

  static CONTENT_GROUP_INDEXES = {
    [TRACKING_CONTENT_GROUP_TYPES.INDUSTRY]: 2,
  };

  // indexes must be between 0 and 20
  static dimensionIndexes = {
    [TRACKING_DIMENSION_KEYS.USER_TYPE]: 1,
  };

  static VALID_DIMENSION_VALUES = {
    [TRACKING_DIMENSION_KEYS.USER_TYPE]: [TRACKING_DIMENSION_VALUES.INTERNAL, ''],
  };

  // https://philipwalton.com/articles/the-ga-setup-i-use-on-every-site-i-build/
  static setDimension(key, val) {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID) {
      return;
    }

    const idx = Tracking.dimensionIndexes[key];
    if (!isValue(idx)) {
      throw new Error('invalid dimension key');
    }
    if (!Tracking.VALID_DIMENSION_VALUES[key]?.includes(val)) {
      throw new Error('invalid dimension value');
    }

    if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
      console.info(`set dimension ${idx}: ${key}, ${val}`);
    }

    window.gtag('set', {
      custom_map: {
        [`dimension${idx}`]: val,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  setInternal(isInternal = true) {
    return Tracking.setDimension(
      TRACKING_DIMENSION_KEYS.USER_TYPE,
      isInternal ? TRACKING_DIMENSION_VALUES.INTERNAL : '',
    );
  }

  static getGoogleEventName(event) {
    switch (event) {
      case TRACKING_EVENTS.PAGEVIEW:
        return 'page_view';
      default:
        return event;
    }
  }

  static standardizeString(str) {
    return str?.toString().trim().toLowerCase();
  }

  static isInternalSaveEvent(event) {
    return [
      TRACKING_EVENTS.CHECKOUT,
      TRACKING_EVENTS.PRICING,
      TRACKING_EVENTS.AUTH,
      TRACKING_EVENTS.DOWNLOAD,
      TRACKING_EVENTS.SUBSCRIPTION,
    ].includes(event);
  }

  static getFacebookClientEventName(event, { action } = {}) {
    switch (event) {
      case TRACKING_EVENTS.PAGEVIEW:
        return Tracking.FB_EVENTS.PAGE_VIEW;
      case TRACKING_EVENTS.SUBSCRIPTION:
        if (action === Tracking.ACTIONS.ATTEMPT) {
          return Tracking.FB_EVENTS.ADD_PAYMENT_INFO;
        }
        if (action === Tracking.ACTIONS.START) {
          return Tracking.FB_EVENTS.START_TRIAL;
        }
        break;
      case TRACKING_EVENTS.AUTH:
        if (action === Tracking.ACTIONS.CREATE) {
          return Tracking.FB_EVENTS.COMPLETE_REGISTRATION;
        }
        break;
      case TRACKING_EVENTS.ZENDESK_WIDGET:
        if (action?.includes('submitted')) {
          return Tracking.FB_EVENTS.CONTACT;
        }
        break;
      case TRACKING_EVENTS.CHECKOUT:
        if (action === Tracking.ACTIONS.START) {
          return Tracking.FB_EVENTS.INITIATE_CHECKOUT;
        }
        break;
      default:
        return null;
    }
    return null;
  }

  static getFacebookServerEventName(event, props) {
    // we only track the initial pageviews on the server
    if (event === TRACKING_EVENTS.PAGEVIEW) {
      const { action } = props || {};
      if (action !== Tracking.ACTIONS.START) {
        return null;
      }
    }

    return Tracking.getFacebookClientEventName(event, props);
  }

  static removePrivateFbData(props) {
    return props ? filterObject(props, /(^geo|_email|^addressLine1)/)?.[1] : props;
  }

  static getFbUserData({
    email,
    standardizedPhone,
    userId,
    gender,
    dateOfBirth,
    city,
    zipCode,
    stateCode,
    countryCode,
    firstName,
    lastName,
    displayName,
    ip,
    ua,
    userAgent,
    subscriptionId,
    fbp,
    fbc,
  } = {}) {
    const { firstName: parsedFirstName, lastName: parsedLastName } = parseDisplayName(displayName);
    return removeEmptyKeys({
      em: Tracking.standardizeString(email),
      fn: Tracking.standardizeString(firstName || parsedFirstName),
      ln: Tracking.standardizeString(lastName || parsedLastName),
      ph: standardizedPhone,
      external_id: userId,
      client_ip_address: ip,
      client_user_agent: userAgent || ua?.ua,
      ge: Tracking.standardizeString(gender),
      db: dateOfBirth,
      ct: Tracking.standardizeString(city),
      st: Tracking.standardizeString(stateCode),
      zp: Tracking.standardizeString(zipCode),
      country: Tracking.standardizeString(countryCode),
      subscription_id: subscriptionId,
      fbp,
      fbc,
    });
  }

  static normalizeFbclid = (fbclid, now = Date.now()) => {
    if (typeof fbclid === 'string' && fbclid.length > 5) {
      return ['fb', 1, now, fbclid].join(PERIOD_DELIMITER);
    }
    return null;
  };

  static getApiOperationSettings = (operation) =>
    TRACKING_API_METHODS[operation.operationName] || null;

  _catchError =
    (fn) =>
    (...args) => {
      try {
        return fn.apply(this, args);
      } catch (error) {
        // prevent max callstack exceed for errors inside of the track function
        if (args[0] !== Tracking.EVENTS.EXCEPTION) {
          this.exception(error);
        }
      }
      return null;
    };

  trackGtm = this._catchError((event, props) => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_GTM_ID) {
      return;
    }

    const eventName = Tracking.getGoogleEventName(event, props);

    if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
      console.info('trackGtm', eventName, event, props);
    }

    window.dataLayer.push({
      ...props,
      event: eventName,
      category: event,
    });
  });

  // trying to deprecate trackGa and run everything through GTM
  trackGa = this._catchError((event, props) => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID) {
      return;
    }

    if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
      console.info('trackGa', event, props);
    }

    const { value: valueProp, predictedLtv, action, label } = props;
    const value = predictedLtv ?? valueProp;
    const eventValue = typeof value === 'number' ? Math.round(value) : undefined;
    const eventName = Tracking.getGoogleEventName(event, props);

    window.gtag('event', eventName, {
      ...props,
      page_location: props.url,
      // below is to continue support for UA
      event_label: label,
      event_value: eventValue,
      event_category: event,
      event_action: action,
    });
  });

  trackFb = this._catchError((event, props) => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_FACEBOOK_ID) {
      return;
    }

    const fbEventName = Tracking.getFacebookClientEventName(event, props);

    if (!fbEventName) {
      return;
    }

    if (!this.fbInitialized) {
      this.initFacebook(props);
    }

    const { value, eventId, predictedLtv, currency = 'USD' } = props;

    const trackProps = removeEmptyKeys({
      ...(Tracking.removePrivateFbData(props) || {}),
      value: value > 0 ? value : null,
      currency: value > 0 ? currency : null,
      predicted_ltv: predictedLtv > 0 ? predictedLtv : null,
      test_event_code: process.env.NEXT_PUBLIC_FACEBOOK_TEST_EVENT_CODE,
    });

    const userData = removeEmptyKeys({
      ...Tracking.getFbUserData(props),
      eventID: eventId,
    });

    window.fbq('track', fbEventName, trackProps, userData);
  });

  trackKlaviyo = this._catchError((event, props) => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_KLAVIYO_KEY) {
      return;
    }

    const eventName = getKlaviyoEventName(event, props);

    if (!eventName) {
      return;
    }

    if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
      console.info('trackKlaviyo', event, props?.action);
    }

    const customerProperties = getKlaviyoIdentifyProperties(this.user, props);

    window._learnq.push([
      'track',
      eventName,
      {
        ...getKlaviyoEventProperties(props),
        ...customerProperties,
      },
    ]);
  });

  isKlaviyoServerEvent(event, props = {}) {
    if (!isKlaviyoIdentifiable(getKlaviyoIdentifyProperties(this.user, props))) {
      return false;
    }

    const { action } = props;

    if (event === TRACKING_EVENTS.PAGEVIEW && action === Tracking.ACTIONS.START) {
      return true;
    }

    if (event === TRACKING_EVENTS.ZENDESK_WIDGET && action?.includes('submitted')) {
      return true;
    }

    return [
      TRACKING_EVENTS.RESUME,
      TRACKING_EVENTS.AUTH,
      TRACKING_EVENTS.SUBSCRIPTION,
      TRACKING_EVENTS.CHECKOUT,
      TRACKING_EVENTS.PRICING,
      TRACKING_EVENTS.DOWNLOAD,
      TRACKING_EVENTS.SAVE_PROGRESS,
    ].includes(event);
  }

  _forwardToParent = (key, fn) =>
    this._catchError((...args) => {
      if (this.parent) {
        return this.parent[key](...args);
      }
      return fn(...args);
    });

  _preventRunningOnChild = (fnName) => {
    if (this.parent) {
      throw new Error(`${fnName} cannot run on a child`);
    }
  };

  createChild = this._catchError(({ context }) => {
    const contextArray = makeArray(context);
    if (!contextArray?.length) {
      throw new Error('contexts is required');
    }

    return new Tracking({
      _parent: this,
      _contexts: [...(this.contexts || []), ...contextArray],
    });
  });

  _initLibrary(name, library) {
    this._preventRunningOnChild('_initLibrary');

    if (__BROWSER__ && process.env.NEXT_PUBLIC_DEBUG === 'tracking' && !library) {
      console.warn(`No library provided for ${name}`);
    }
    return (method, ...args) => {
      if (this.disabled) {
        return undefined;
      }
      if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
        console.info(`[${name}${library ? '' : '-no-lib'}]: ${method}`, ...args);
      }
      if (library && typeof library[method] === 'function') {
        return library[method](...args);
      }
      if (process.env.NEXT_PUBLIC_DEBUG === 'tracking' && library) {
        console.warn(`${method} is not a function for ${name}`);
      }
      return undefined;
    };
  }

  initSentry = this._catchError(() => {
    this._preventRunningOnChild('initSentry');
    // eslint-disable-next-line global-require
    this._sentry = this._initLibrary('Sentry', Sentry);
  });

  initTracking = this._catchError(() => {
    if (!__BROWSER__) {
      return;
    }

    this._preventRunningOnChild('initTracking');

    const queryParams = parseBrowserQuery({ keyPrefix: SESSION_QUERY_PREFIX });
    const timezoneOffset = new Date().getTimezoneOffset();
    this.addDefaultProps({
      ...(queryParams ?? {}),
      debug: process.env.NEXT_PUBLIC_DEBUG === 'tracking',
      timezoneOffset,
      ...vercelEnvVars,
    });
  });

  initMixpanel = this._catchError(() => {
    this._preventRunningOnChild('initMixpanel');
    this._mixpanel = this._initLibrary('Mixpanel', mixpanel);
    if (IS_MIXPANEL) {
      this._mixpanel('init', process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);
    }
  });

  initGoogle = this._catchError(() => {
    if (!__BROWSER__) {
      return;
    }
    this._preventRunningOnChild('initGoogle');
    // support so we can remove analytics script if needed
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      window.dataLayer = window.dataLayer || [];
    }

    // the following is analytics code and can be removed if
    if (process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID) {
      const propertyIds = parseCsvString(process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID);

      if (!propertyIds?.length) {
        throw new Error('invalid property tracking ids');
      }

      window.dataLayer = window.dataLayer || [];
      window.gtag =
        window.gtag ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        };
      window.gtag('js', new Date());

      propertyIds.forEach((propertyId) => {
        if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
          console.info('initGoogle propertyId', propertyId);
        }
        window.gtag('config', propertyId, {
          send_page_view: false,
          debug_mode: process.env.NEXT_PUBLIC_DEBUG === 'tracking-gtag',
        });
      });
    }
  });

  initFacebook = this._catchError((props) => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_FACEBOOK_ID || this.fbInitialized) {
      return;
    }
    this._preventRunningOnChild('initFacebook');

    const userData = Tracking.getFbUserData(this._trackingProps(props));
    window.fbq('init', process.env.NEXT_PUBLIC_FACEBOOK_ID, userData);
    this.fbInitialized = true;
  });

  initZendesk = this._catchError(() => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_ZENDESK_KEY) {
      return;
    }

    this._preventRunningOnChild('initZendesk');

    if (this.user) {
      this.identifyZendeskUser();
    }

    zendeskClient('webWidget:on', 'userEvent', ({ action, category, properties = {} }) => {
      this.track(
        category === 'Zendesk Web Widget' ? TRACKING_EVENTS.ZENDESK_WIDGET : createSlug(category),
        {
          ...properties,
          action: createSlug(action),
        },
      );
    });
  });

  initKlaviyo = this._catchError(() => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_KLAVIYO_KEY) {
      return;
    }
    window._learnq = window._learnq || [];
  });

  addDefaultProp = this._forwardToParent('addDefaultProp', (key, value) => {
    if (typeof value !== 'undefined' && this.defaultProps[key] !== value) {
      this.defaultProps = {
        ...this.defaultProps,
        [key]: value,
      };
    }
    return this.defaultProps;
  });

  addDefaultProps = this._forwardToParent('addDefaultProps', (obj) => {
    if (!isValue(obj)) {
      return this.defaultProps;
    }
    Object.keys(obj).forEach((key) => {
      this.addDefaultProp(key, obj[key]);
    });
    return this.defaultProps;
  });

  addPathProp = this._forwardToParent('addPathProp', (path, key, value) => {
    if (typeof value !== 'undefined') {
      this.pathProps[path] = { ...(this.pathProps[path] || {}), [key]: value || null };
    }
    return this.pathProps[path];
  });

  addPathProps = this._forwardToParent('addPathProps', (path, obj) => {
    if (typeof obj !== 'undefined') {
      this.pathProps[path] = { ...(this.pathProps[path] || {}), ...(obj || {}) };
    }
    return this.pathProps[path];
  });

  // props where the child takes precedence
  _childProps = () => {
    return this.contexts?.length
      ? {
          contexts: this.contexts,
        }
      : {};
  };

  static getCookieValue(cookieId) {
    if (typeof window === 'undefined') {
      return null;
    }
    return window[WINDOW_REDUX_KEY].store.dispatch((d, g, { cookies }) => cookies.get(cookieId));
  }

  static setCookieValue(cookieId, value) {
    if (typeof window === 'undefined') {
      return null;
    }
    return window[WINDOW_REDUX_KEY].store.dispatch((d, g, { cookies }) =>
      cookies.set(cookieId, value),
    );
  }

  // these props don't usually change and can be captured at time of sending the event
  _sendTimeProps() {
    this._preventRunningOnChild('_sendTimeProps');

    const reduxState = this.getState?.() || null;
    const assignmentTracking = selectAssignmentTracking(reduxState);
    const isAssignmentOverrides = !!selectAssignmentOverrides(reduxState);
    const isAssignmentReady = selectIsAssignmentReady(reduxState);
    const userConfig = selectUserConfig(reduxState);
    const { id: userId, profile } = selectUser(reduxState) || {};
    const interests = selectNormalizedInterests(reduxState);
    const mostRecentResumeParams = createSelectNextResumeBuilderParams({
      id: selectMostRecentResumeId(reduxState),
    })(reduxState);
    const isMostRecentResumeParams = !!mostRecentResumeParams;
    const mostRecentResumeSection = mostRecentResumeParams
      ? generateSectionStepKey(mostRecentResumeParams)
      : null;

    const queryParams = parseBrowserQuery();

    const fbc =
      userConfig?.fbc ||
      Tracking.getCookieValue(FB_USER_CLICK_COOKIE_NAME) ||
      Tracking.normalizeFbclid(
        this.defaultProps?.[`${SESSION_QUERY_PREFIX}${FACEBOOK_CLICK_ID_QUERY}`],
      ) ||
      Tracking.normalizeFbclid(queryParams?.[FACEBOOK_CLICK_ID_QUERY]);

    if (fbc && fbc !== userConfig?.fbc) {
      this.dispatch?.(setUserConfigAction({ fbc }));
    }

    const mostResumeResumeId =
      mostRecentResumeParams?.[RESUME_ID_QUERY] ||
      queryParams?.[RESUME_ID_QUERY] ||
      this.defaultProps?.[`${SESSION_QUERY_PREFIX}${RESUME_ID_QUERY}`];

    // todo: resume emails
    // todo: street address
    // TODO: createSelectUserLocation so we aren't mixing and matching sources
    return removeEmptyKeys({
      server: __SERVER__,
      ...userConfig,
      ...assignmentTracking,
      ...(profile || null),
      ...interests,
      isAssignmentOverrides,
      isMostRecentResumeParams,
      mostRecentResumeSection: mostRecentResumeSection ?? null,
      [MOST_RECENT_RESUME_ID]: mostResumeResumeId ?? null,
      fbc: fbc ?? null,
      isAssignmentReady,
      userId: userId ?? null,
      email: selectUserContactEmail(reduxState) ?? null,
      firstName: selectUserFirstName(reduxState) ?? null,
      lastName: selectUserLastName(reduxState) ?? null,
      displayName: selectUserDisplayName(reduxState) ?? null,
      standardizedPhone: reduxState ? createSelectUserStandardizedPhone()(reduxState) : null,
      city: selectUserCity(reduxState) ?? null,
      stateCode: selectUserStateCode(reduxState) ?? null,
      countryCode: selectUserCountryCode(reduxState) ?? null,
      zipCode: selectUserPostalCode(reduxState) ?? null,
      forceReady: this.forceReady,
      ready: this.ready,
      ...this._childProps(),
      eventId: createId(),
    });
  }

  // these props can change and need to be captured at time of the event
  _eventTimeProps() {
    this._preventRunningOnChild('_eventTimeProps');

    const screen = getScreen();
    const path = getPagePath();
    const url = getPageUrl();
    const referrer = __BROWSER__ ? document.referrer : null;
    const { asPath, pathname } = __BROWSER__ ? router : {};
    const queryParams = parseBrowserQuery({ keyPrefix: EVENT_QUERY_PREFIX });
    const reduxState = this.getState?.() ?? null;
    const assignmentTracking = selectAssignmentTracking(reduxState);

    return removeEmptyKeys({
      ...assignmentTracking,
      server: __SERVER__,
      ...screen,
      ...queryParams,
      referrer,
      eventTime: getTime(),
      url,
      path,
      pathname,
      asPath,
      ...this._childProps(),
    });
  }

  _trackingProps(incomingProps) {
    this._preventRunningOnChild('_trackingProps');

    const { path } = incomingProps;
    const pathProps = path && this.pathProps?.[path] ? this.pathProps[path] : {};

    const userProps = {
      ...pathProps,
      ...(incomingProps || null),
    };

    const props = removeEmptyKeys({
      ...this.defaultProps,
      ...userProps,
      path,
    });

    props.label = props.label || DEFAULT_TEXT;
    props.action = props.action || Tracking.ACTIONS.DEFAULT;

    return createTrackingProps(props);
  }

  registerDispatch = this._forwardToParent('registerDispatch', (dispatch) => {
    this.dispatch = dispatch;
  });

  registerGetState = this._forwardToParent('registerGetState', (getState) => {
    this.getState = getState;
    const user = selectUser(getState());
    if (user) {
      this.registerUser(user);
    }
  });

  identifyKlaviyoUser = this._forwardToParent('identifyKlaviyoUser', (user, props = {}, resume) => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_KLAVIYO_KEY) {
      return;
    }

    const klaviyoProps = getKlaviyoIdentifyProperties(user, props, resume);

    if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
      console.info('identifyKlaviyoUser', klaviyoProps);
    }

    if (!isKlaviyoIdentifiable(klaviyoProps)) {
      return;
    }

    window._learnq.push(['identify', klaviyoProps]);
  });

  identifyZendeskUser = this._catchError(() => {
    if (!__BROWSER__ || !process.env.NEXT_PUBLIC_ZENDESK_KEY) {
      return;
    }
    this._preventRunningOnChild('identifyZendeskUser');
    if (this.user?.email) {
      const { email, profile } = this.user;
      const { displayName } = profile || {};
      const zendeskUser = {
        name: displayName || email,
        email,
      };
      zendeskClient('webWidget', 'identify', zendeskUser);
    } else {
      zendeskClient('webWidget', 'logout');
    }
  });

  registerUser = this._forwardToParent('registerUser', (user = null) => {
    if (this.disabled) {
      return;
    }
    this.user = user;

    if (this.user) {
      const { id: userId, email, profile } = this.user;
      const { displayName } = profile || {};
      if (userId) {
        this._mixpanel('identify', userId);

        let { firstName, lastName } = parseDisplayName(displayName);
        const reduxState = this.getState?.() || null;
        const phone = reduxState ? createSelectUserStandardizedPhone()(reduxState) : null;
        if (reduxState) {
          firstName = selectUserFirstName(reduxState);
          lastName = selectUserLastName(reduxState);
        }

        if (mixpanel && (email || displayName || phone || firstName || lastName)) {
          mixpanel.people.set(
            removeEmptyKeys({
              $name: displayName || filterJoin([firstName, lastName], SPACE_STRING),
              $email: email,
              $phone: phone,
            }),
          );
        }

        this.identifyKlaviyoUser(this.user, null, selectResumeValue(reduxState));

        // this function can run without gtag if the env isn't set
        if (__BROWSER__ && typeof window?.gtag === 'function') {
          window.gtag('set', { userId });
        }
      }
      if (userId || email) {
        this._sentry('configureScope', (scope) => {
          scope.setUser({ id: userId, email });
        });
      }
    } else {
      this._mixpanel('reset');
      // this function can run without gtag if the env isn't set
      if (__BROWSER__ && typeof window?.gtag === 'function') {
        window.gtag('set', { userId: null });
      }
      this._sentry('configureScope', (scope) => {
        scope.setUser(null);
      });
      window._learnq.push(['clearIdentity']);
    }
    this.identifyZendeskUser();
  });

  disable = this._forwardToParent('disable', (set = true) => {
    if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
      console.info('tracking disable()', set);
    }
    this.disabled = set;
  });

  getDisabled = this._forwardToParent('getDisabled', () => {
    return this.disabled;
  });

  track(event, props, childProps) {
    if (this.parent) {
      return this.parent.track(event, props, childProps || this._childProps());
    }
    if (this.disabled) {
      return null;
    }
    if (__SERVER__) {
      return this._sendTrack(event, props);
    }
    if (!event) {
      throw new Error(`No event provided`);
    }
    this.queue.push({
      event,
      props: this._trackingProps({ ...this._eventTimeProps(), ...props, ...childProps }),
    });
    return this.schedulePendingEvents();
  }

  handleBeforeUnload() {
    this.processPendingAnalyticsEvents();
  }

  schedulePendingEvents() {
    this._preventRunningOnChild('schedulePendingEvents');

    if (this.disabled || this.queue.length === 0) {
      return;
    }

    if (!this.isReady()) {
      if (this.queue.length > 0 && !this.scheduledInterval) {
        this.scheduledInterval = setInterval(this.schedulePendingEvents, 2 * ONE_SEC_MS);
      }
      return;
    }

    if (this.scheduledInterval) {
      clearInterval(this.scheduledInterval);
      this.scheduledInterval = null;
    }

    // Only schedule the rIC if one has not already been set.
    if (this.requestIdleCallbackId) {
      return;
    }

    if (__BROWSER__ && 'requestIdleCallback' in window) {
      // Wait at most 6 seconds before processing events.
      this.requestIdleCallbackId =
        window.requestIdleCallback(this.processPendingAnalyticsEvents, {
          timeout: 6 * ONE_SEC_MS,
        }) || true;
      window.addEventListener('beforeunload', this.handleBeforeUnload, {
        passive: true,
        once: true,
      });
    } else {
      this.processPendingAnalyticsEvents();
    }
  }

  processPendingAnalyticsEvents = this._forwardToParent(
    'processPendingAnalyticsEvents',
    (deadline) => {
      // cancel the requestIdleCallbackId if its a valid id and if it is a true callbackId -
      // this only matters if processPendingAnalyticsEvents runs without being scheduled (ie session exit)
      if (
        __BROWSER__ &&
        this.requestIdleCallbackId !== true &&
        isValue(this.requestIdleCallbackId) &&
        'cancelIdleCallback' in window
      ) {
        window.cancelIdleCallback(this.requestIdleCallbackId);
      }
      // Reset the boolean so future rICs can be set.
      this.requestIdleCallbackId = null;

      // If there is no deadline, just run as long as necessary.
      // This will be the case if requestIdleCallback doesn't exist.
      if (typeof deadline === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        deadline = {
          timeRemaining: () => Number.MAX_VALUE,
        };
      }

      // Go for as long as there is time remaining and work to do.
      while (deadline.timeRemaining() > 0 && this.queue.length > 0) {
        // FIFO
        const { event, props } = this.queue.shift();
        this._sendTrack(event, props);
      }

      if (__BROWSER__ && this.queue.length === 0) {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
      }

      // Check if there are more events still to send.
      if (this.queue.length > 0) {
        this.schedulePendingEvents();
      }
    },
  );

  isReady() {
    this._preventRunningOnChild('isReady');

    if (!this.ready) {
      if (typeof this.getState === 'function' && typeof this.dispatch === 'function') {
        const state = this.getState();
        if (selectReady(state)) {
          if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
            console.info('tracking ready');
          }
          this.ready = true;
          if (this.forceReadyTimeout) {
            clearTimeout(this.forceReadyTimeout);
          }
          this.schedulePendingEvents();
        }
      }
    }

    return this.ready || this.forceReady;
  }

  _sendTrack(event, props) {
    this._preventRunningOnChild('_sendTrack');

    if (this.disabled) {
      return;
    }

    if (props.preventTrack) {
      return;
    }

    const finalProps = this._trackingProps({ ...this._sendTimeProps(), ...removeEmptyKeys(props) });

    if (process.env.NEXT_PUBLIC_DEBUG === 'tracking') {
      console.info('Tracking._sendTrack', event, finalProps?.action, finalProps);
    }

    this.trackInternal(event, finalProps);
    this._mixpanel('track', event, finalProps);
    this.trackKlaviyo(event, finalProps);
    this.trackGa(event, finalProps);
    this.trackGtm(event, finalProps);
    this.trackFb(event, finalProps);
  }

  trackInternal = this._catchError((event, props) => {
    this._preventRunningOnChild('trackInternal');
    if (
      process.env.NODE_ENV !== 'production' &&
      !process.env.NEXT_PUBLIC_DEV_TRACK_INTERNAL_EVENTS
    ) {
      return null;
    }
    const { action, label } = props;

    const track = () =>
      this.dispatch?.((d, s, { client }) =>
        client.mutate({
          mutation: trackEventMutation,
          variables: {
            event: {
              name: event,
              action,
              label,
              data: props,
            },
          },
        }),
      );

    if (
      Tracking.isInternalSaveEvent(event, props) ||
      Tracking.getFacebookServerEventName(event, props) ||
      this.isKlaviyoServerEvent(event, props)
    ) {
      track();
    }

    return null;
  });

  exception = (error, props = {}) => {
    if (this.parent) {
      return this.parent.exception(error, props);
    }

    if (!error) {
      return null;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    }

    const { errorInfo, ...otherProps } = props;

    this._sentry?.('withScope', (scope) => {
      if (errorInfo && typeof errorInfo === 'object') {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
      }
      scope.setLevel(otherProps.level || ERROR_LEVEL);
      this._sentry('captureException', error);
    });

    let errorInfoMessage = '';
    if (typeof errorInfo === 'object') {
      errorInfoMessage = Object.values(errorInfo).join();
    } else if (typeof errorInfo === 'string') {
      errorInfoMessage = errorInfo;
    }
    const gaProps = {
      description: error.message,
      fatal: otherProps.level === FATAL_LEVEL,
      nonInteraction: true,
    };

    this.trackGa(Tracking.EVENTS.EXCEPTION, gaProps);
    this.trackGtm(Tracking.EVENTS.EXCEPTION, { ...props, ...gaProps });

    this._mixpanel?.(
      'track',
      Tracking.EVENTS.EXCEPTION,
      this._trackingProps({
        ...this._sendTimeProps(),
        ...this._eventTimeProps(),
        message: error.message, // deprecated until 6/2022
        ...otherProps,
        errorInfo: errorInfoMessage,
        label: error.message,
      }),
    );
    return null;
  };

  // ****************************** USER ACTIONS / EVENTS
  click = this._catchError((props = {}) => this.track(TRACKING_EVENTS.CLICK, props));

  subscription = this._catchError((props = {}) => this.track(TRACKING_EVENTS.SUBSCRIPTION, props));

  attemptSubscription = this._catchError((props = {}) =>
    this.subscription({
      ...props,
      action: Tracking.ACTIONS.ATTEMPT,
    }),
  );

  static isTrackedSubscription = (subscriptionId) => {
    const trackedSubscriptions = Tracking.getCookieValue(SUBSCRIPTION_TRACKING_COOKIE_NAME) || [];
    return trackedSubscriptions.includes(subscriptionId);
  };

  createSubscription = this._catchError((props = {}) => {
    const { subscriptionId } = props;
    if (!subscriptionId) {
      throw new Error('tracking subscriptionId is required');
    }

    if (Tracking.isTrackedSubscription(subscriptionId)) {
      throw new Error(`tracking subscriptionId ${subscriptionId} already tracked`);
    }

    this.subscription({
      ...props,
      action: Tracking.ACTIONS.START,
    });

    const trackedSubscriptions = Tracking.getCookieValue(SUBSCRIPTION_TRACKING_COOKIE_NAME) || [];
    Tracking.setCookieValue(
      SUBSCRIPTION_TRACKING_COOKIE_NAME,
      [subscriptionId, ...trackedSubscriptions].slice(0, 3),
    );

    return true;
  });

  pricing = this._catchError((props = {}) =>
    this.track(TRACKING_EVENTS.PRICING, {
      action: Tracking.ACTIONS.START,
      ...props,
    }),
  );

  checkout = this._catchError((props = {}) =>
    this.track(TRACKING_EVENTS.CHECKOUT, {
      action: Tracking.ACTIONS.START,
      ...props,
    }),
  );

  download = this._catchError((props = {}) => this.track(TRACKING_EVENTS.DOWNLOAD, props));

  communication = this._catchError((props = {}) => {
    const { action, label } = props;
    if (![TRACKING_LABELS.EMAIL].includes(label) || !action) {
      throw new Error(`invalid communication props: ${JSON.stringify(props)}`);
    }

    return this.track(TRACKING_EVENTS.COMMUNICATION, props);
  });

  auth = this._catchError((props = {}) => {
    if (props.action === Tracking.ACTIONS.start) {
      throw new Error('use login method');
    }
    this.track(TRACKING_EVENTS.AUTH, props);
  });

  createAuth = (props = {}) => this.auth({ ...props, action: Tracking.ACTIONS.CREATE });

  login = this._catchError((user, props = {}) => {
    if (user) {
      this.auth({ ...props, action: Tracking.ACTIONS.START });
    }
  });

  logout = this._catchError((props = {}) => {
    this.registerUser();
    this.auth({ ...props, action: Tracking.ACTIONS.END });
  });

  pageView = this._catchError((props = {}) => this.track(TRACKING_EVENTS.PAGEVIEW, props));

  session = this._catchError((props = {}) => this.track(TRACKING_EVENTS.SESSION, props));

  share = this._catchError((props = {}) => this.track(TRACKING_EVENTS.SHARE, props));

  search = this._catchError((props = {}) => this.track(TRACKING_EVENTS.SEARCH, props));

  resume = this._catchError((props = {}) => this.track(TRACKING_EVENTS.RESUME, props));

  resumeImprovements = this._catchError((props = {}) =>
    this.track(TRACKING_EVENTS.RESUME_IMPROVEMENTS, props),
  );

  userPackage = this._catchError((props = {}) => this.track(TRACKING_EVENTS.USER_PACKAGE, props));

  assignments = this._catchError((props = {}) => this.track(TRACKING_EVENTS.ASSIGNMENTS, props));

  apiOperation = this._catchError((operation, { error, result } = {}) => {
    const { getTrackingProps, ...otherSettings } =
      Tracking.getApiOperationSettings(operation) || {};

    let initialTrackingProps;
    if (typeof getTrackingProps === 'function') {
      initialTrackingProps = getTrackingProps(operation, result);
    }

    const {
      eventName,
      sampleRate = eventName ? DEFAULT_NAMED_SAMPLE_RATE : DEFAULT_ANONYMOUS_SAMPLE_RATE,
    } = { ...otherSettings, ...initialTrackingProps };

    const duration = new Date() - operation.getContext().startTime;
    const isExcessiveDuration = duration > EXCESSIVE_DURATION;
    const isRandomlySampled = Math.random() < sampleRate;

    if (isExcessiveDuration || isRandomlySampled || !!error) {
      const trackingProps = {
        ...initialTrackingProps,
        action: operation.operationName,
        label: TRACKING_EVENTS.API,
        duration,
        isExcessiveDuration,
        isRandomlySampled,
        sampleRate,
        error: error
          ? {
              message: error.message,
              stack: error.stack,
            }
          : null,
        variables: operation.variables ? createTrackingProps(operation.variables) : null,
      };

      this.track(eventName || TRACKING_EVENTS.API, trackingProps);
    }
  });

  churnkey = this._catchError((props = {}) => this.track(TRACKING_EVENTS.CHURNKEY, props));
}

export default Tracking;
