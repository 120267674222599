import createSearchString from '@glass/common/modules/url/createSearchString';

const createUrl = ({
  origin,
  pathname,
  query,
}: {
  origin?: string;
  pathname?: string;
  query?: Record<string, unknown> | null;
}) => `${origin || ''}${pathname || '/'}${createSearchString(query)}`;

export default createUrl;
