import countries from '@glass/common/modules/geo/countries';
import findCountry from '@glass/common/modules/geo/findCountry';
import getResumeContactCountry from '@glass/common/modules/resumes/selectors/getResumeContactCountry';
import { selectUserProfile } from '@glass/web/modules/auth';
import { selectResumeValue } from '@glass/web/modules/resumes/selectors';
import selectGeoCountry from '@glass/web/modules/userConfig/selectors/selectGeoCountry';

import selectPaymentFormCountry from '@glass/shared/modules/resumes/selectors/selectPaymentFormCountry';

// todo remove countries dependency, which should only be fetched asynchronously on the client
const selectUserCountryCode = (state) => {
  const resume = selectResumeValue(state);
  const resumeCountryCode = findCountry(getResumeContactCountry(resume), countries)?.code;
  if (resumeCountryCode) {
    return resumeCountryCode;
  }

  const paymentCountry = selectPaymentFormCountry(state);
  const paymentCountryCode = findCountry(paymentCountry, countries)?.code;

  if (paymentCountryCode) {
    return paymentCountryCode;
  }

  const { country: profileCountry } = selectUserProfile(state) || {};
  const profileCountryCode = findCountry(profileCountry, countries)?.code;
  if (profileCountryCode) {
    return profileCountryCode;
  }

  return findCountry(selectGeoCountry(state)?.isoCode, countries)?.code || null;
};

export default selectUserCountryCode;
