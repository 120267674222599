import makeArray from '@glass/common/modules/utils/makeArray';

import {
  INDUSTRIES_KEY,
  JOB_TITLES_KEY,
  PUSH_INTERESTS,
  RESOURCE_TAGS_KEY,
} from '@glass/shared/modules/store/constants';

const makeResources = (resources) => makeArray(resources).map(({ slug, name }) => ({ slug, name }));

export const pushIndustriesInterest = (industries) => ({
  type: PUSH_INTERESTS,
  payload: {
    [INDUSTRIES_KEY]: makeResources(industries),
  },
});

export const pushJobTitlesInterest = (jobTitles) => ({
  type: PUSH_INTERESTS,
  payload: {
    [JOB_TITLES_KEY]: makeResources(jobTitles),
  },
});

const invalidSlugRegex = /^_blog/;

export const pushResourcesInterest = (resources) => (dispatch) => {
  const resourceTags = makeArray(resources).reduce((agg, resource) => {
    const slug = resource?.slug || resource;
    return typeof slug === 'string' && !slug.match(invalidSlugRegex) ? [...agg, slug] : agg;
  }, []);
  if (resourceTags.length) {
    dispatch({
      type: PUSH_INTERESTS,
      payload: {
        [RESOURCE_TAGS_KEY]: resourceTags,
      },
    });
  }
};
