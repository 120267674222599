const createHandleFetchResponse =
  (context = '') =>
  (response: Response) => {
    if (response.ok) {
      return response;
    }
    return response.text().then((text: string) => {
      throw new Error(`Failed to fetch @ ${context}: ${text}`);
    });
  };

export default createHandleFetchResponse;
