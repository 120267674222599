import {
  ADVERB_CAPITALIZED_KEY,
  ADVERB_KEY,
  CAPITALIZED_KEY,
  createStartCaseWordFn,
  createWorderWordFn,
  createWordVariations,
  DEFAULT_CONTENT_CREATE_FUNCTIONS,
  SINGULAR_KEY,
  type VariationKey,
  type CreateWordVariationsOptions,
} from '@glass/common/modules/content/staticVariations/createWordVariations';

export const BEST_ADJECTIVE_WORD = 'best';
export const PERFECT_ADJECTIVE_WORD = 'perfect';
export const FREE_ADJECTIVE_WORD = 'free';
export const EASY_ADJECTIVE_WORD = 'easy';
export const FAST_ADJECTIVE_WORD = 'fast';
export const SIMPLE_ADJECTIVE_WORD = 'simple';
export const SMART_ADJECTIVE_WORD = 'smart';
export const PROFESSIONAL_ADJECTIVE_WORD = 'professional';
export const GREAT_ADJECTIVE_WORD = 'great';

export const BEST_ADVERB_WORD = 'better';
export const PERFECT_ADVERB_WORD = 'perfectly';
export const FREE_ADVERB_WORD = 'for free';
export const EASY_ADVERB_WORD = 'easier';
export const PROFESSIONAL_ADVERB_WORD = 'professionally';
export const GREAT_ADVERB_WORD = 'greater';
export const FAST_ADVERB_WORD = 'faster';
export const SMART_ADVERB_WORD = 'smarter';

export const ADJECTIVE_WORD_KEYS: Array<VariationKey> = [
  SINGULAR_KEY,
  CAPITALIZED_KEY,
  ADVERB_KEY,
  ADVERB_CAPITALIZED_KEY,
];

const ADJECTIVE_WORD_CREATE_FUNCTIONS = {
  ...DEFAULT_CONTENT_CREATE_FUNCTIONS,
  [ADVERB_KEY]: createWorderWordFn(SINGULAR_KEY),
  [ADVERB_CAPITALIZED_KEY]: createStartCaseWordFn(ADVERB_KEY),
};

const defaults: { [key: string]: { [key2: string]: string } } = {
  [BEST_ADJECTIVE_WORD]: {
    [ADVERB_KEY]: BEST_ADVERB_WORD,
  },
  [PERFECT_ADJECTIVE_WORD]: {
    [ADVERB_KEY]: PERFECT_ADVERB_WORD,
  },
  [FREE_ADJECTIVE_WORD]: {
    [ADVERB_KEY]: FREE_ADVERB_WORD,
  },
  [EASY_ADJECTIVE_WORD]: {
    [ADVERB_KEY]: EASY_ADVERB_WORD,
  },
  [SIMPLE_ADJECTIVE_WORD]: {
    [ADVERB_KEY]: EASY_ADVERB_WORD,
  },
  [PROFESSIONAL_ADJECTIVE_WORD]: {
    [ADVERB_KEY]: PROFESSIONAL_ADVERB_WORD,
  },
};

export const createAdjectiveWords = (variationKey: string, options?: CreateWordVariationsOptions) =>
  variationKey
    ? createWordVariations(
        {
          [SINGULAR_KEY]: variationKey,
          ...(defaults[variationKey] || {}),
        },
        ADJECTIVE_WORD_KEYS,
        ADJECTIVE_WORD_CREATE_FUNCTIONS,
        options,
      )
    : null;

export const DEFAULT_ADJECTIVE_WORDS = createAdjectiveWords(BEST_ADJECTIVE_WORD, {
  isBrowserSafe: true,
});

const adjectiveWordVariations = [
  BEST_ADJECTIVE_WORD,
  PERFECT_ADJECTIVE_WORD,
  FREE_ADJECTIVE_WORD,
  EASY_ADJECTIVE_WORD,
  FAST_ADJECTIVE_WORD,
  SIMPLE_ADJECTIVE_WORD,
  SMART_ADJECTIVE_WORD,
  PROFESSIONAL_ADJECTIVE_WORD,
];

export const weightedAdjectiveVariations = [
  { value: PROFESSIONAL_ADJECTIVE_WORD, weight: 7 },
  { value: SMART_ADJECTIVE_WORD, weight: 5 },
  { value: PERFECT_ADJECTIVE_WORD, weight: 5 },
  { value: FAST_ADJECTIVE_WORD, weight: 2 },
  { value: BEST_ADJECTIVE_WORD, weight: 2 },
];

export default adjectiveWordVariations;
