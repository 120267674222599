import { loadStripe as loadStripePure } from '@stripe/stripe-js/pure';

import { STRIPE_API_VERSION_DEFAULT } from '@glass/common/modules/stripe/apiVersions';
import setupRequestIdleCallbackShim from '@glass/web/modules/loading/setupRequestIdleCallbackShim';

setupRequestIdleCallbackShim();

let idleCallbackId: number;

function loadStripeNow() {
  if (typeof idleCallbackId !== 'undefined' && typeof window !== 'undefined') {
    window.cancelIdleCallback(idleCallbackId);
  }
  // this will preload stripe so the script is on all pages
  return loadStripePure(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_API_KEY || '', {
    apiVersion: STRIPE_API_VERSION_DEFAULT,
  });
}

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  const READY_STATE_EVENT_TYPE = 'readystatechange';
  // always load after page is loaded and then when the browser is idle
  const loadStripeLazy = () => {
    if (document.readyState === 'complete') {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      idleCallbackId = window.requestIdleCallback((deadline) => {
        if (deadline.timeRemaining() > 0) {
          // eslint-disable-next-line no-void
          void loadStripeNow();
          document.removeEventListener(READY_STATE_EVENT_TYPE, loadStripeLazy);
          if (idleCallbackId) {
            window.cancelIdleCallback(idleCallbackId);
          }
        }
      });
    }
  };

  if (document.readyState === 'complete') {
    loadStripeLazy();
  } else {
    document.addEventListener(READY_STATE_EVENT_TYPE, loadStripeLazy);
  }
}

export default loadStripeNow;
