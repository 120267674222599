import {
  ApolloLink,
  Observable,
  type Operation,
  type NextLink,
  type FetchResult,
} from '@apollo/client/core';
import type { DefinitionNode } from 'graphql';

import TimeoutError from '@glass/shared/modules/apollo/TimeoutError';

interface FetchOptions {
  controller?: AbortController | null;
  signal?: AbortSignal | null;
}

interface TimeoutContext {
  timeout?: number;
  fetchOptions?: FetchOptions;
  timeoutRef?: (ref: { unsubscribe: () => void }) => void;
}

type OperationContext = Record<string, any> & TimeoutContext;

const DEFAULT_TIMEOUT = 15000;
/**
 * Aborts the request if the timeout expires before the response is received.
 * IMPORTANT NOTE: This is a copy of the apollo-link-timeout@npm:4.0.0 library
 * adding more details to the error message.
 */
export default class ApolloLinkTimeout extends ApolloLink {
  private timeout: number;

  private statusCode?: number;

  constructor(timeout: number, statusCode?: number) {
    super();
    this.timeout = timeout || DEFAULT_TIMEOUT;
    this.statusCode = statusCode;
  }

  public request(operation: Operation, forward: NextLink) {
    let controller: AbortController;

    // override timeout from query context
    const requestTimeout = (operation.getContext().timeout as number) || this.timeout;

    // add abort controller and signal object to fetchOptions if they don't already exist
    if (typeof AbortController !== 'undefined') {
      const context = operation.getContext() as OperationContext;
      let fetchOptions = context.fetchOptions || {};

      controller = fetchOptions.controller || new AbortController();

      fetchOptions = { ...fetchOptions, controller, signal: controller.signal };
      operation.setContext({ fetchOptions });
    }

    const chainObservable = forward(operation); // observable for remaining link chain

    const operationType = (
      operation.query.definitions as Array<DefinitionNode & { operation: string }>
    )?.find((def) => def.kind === 'OperationDefinition')?.operation;

    if (requestTimeout <= 0 || operationType === 'subscription') {
      return chainObservable; // skip this link if timeout is zero or it's a subscription request
    }

    // create local observable with timeout functionality (unsubscibe from chain observable and
    // return an error if the timeout expires before chain observable resolves)
    const localObservable = new Observable<FetchResult>((observer) => {
      let timer: NodeJS.Timeout;

      // listen to chainObservable for result and pass to localObservable if received before timeout
      const subscription = chainObservable.subscribe(
        (result) => {
          clearTimeout(timer);
          observer.next(result);
          observer.complete();
        },
        (error) => {
          clearTimeout(timer);
          observer.error(error);
          observer.complete();
        },
      );

      // if timeout expires before observable completes, abort call, unsubscribe, and return error
      timer = setTimeout(() => {
        if (controller) {
          controller.abort(); // abort fetch operation

          // if the AbortController in the operation context is one we created,
          // it's now "used up", so we need to remove it to avoid blocking any
          // future retry of the operation.
          const context = operation.getContext() as OperationContext;
          let fetchOptions = context.fetchOptions || {};
          if (fetchOptions.controller === controller && fetchOptions.signal === controller.signal) {
            fetchOptions = { ...fetchOptions, controller: null, signal: null };
            operation.setContext({ fetchOptions });
          }
        }

        observer.error(
          new TimeoutError(
            `Timeout exceeded on operation ${operation.operationName}. Variables ${JSON.stringify(
              operation.variables,
            )}`,
            requestTimeout,
            this.statusCode,
          ),
        );
        subscription.unsubscribe();
      }, requestTimeout);

      const ctxRef = (operation.getContext() as OperationContext).timeoutRef;

      if (ctxRef) {
        ctxRef({
          unsubscribe: () => {
            clearTimeout(timer);
            subscription.unsubscribe();
          },
        });
      }

      // this function is called when a client unsubscribes from localObservable
      return () => {
        clearTimeout(timer);
        subscription.unsubscribe();
      };
    });

    return localObservable;
  }
}
