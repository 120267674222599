import countries from '@glass/common/modules/geo/countries';
import findCountry from '@glass/common/modules/geo/findCountry';
import findState from '@glass/common/modules/geo/findState';
import { COUNTRY_FIELD, STATE_FIELD } from '@glass/common/modules/resume-builder/constants';

const longVersionGeo = (value: string, field: string) => {
  if (typeof value !== 'string') {
    return value as string;
  }
  if (field?.includes(COUNTRY_FIELD)) {
    const long = findCountry(value, countries);
    return long?.label || value;
  }
  if (field?.includes(STATE_FIELD)) {
    const long = findState(value);
    return long?.label || value;
  }
  return value;
};

export default longVersionGeo;
