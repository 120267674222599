import pricingDefinitionEnCa from '@glass/common/modules/payments/prices/en-ca/pricingDefinition';
import pricingDefinitionEnUs from '@glass/common/modules/payments/prices/en-us/pricingDefinition';
import pricingDefinitionFrCa from '@glass/common/modules/payments/prices/fr-ca/pricingDefinition';
import { type PricingDefinitionType } from '@glass/common/modules/payments/types';
import {
  LOCALE_EN_US,
  LOCALE_EN,
  LOCALE_EN_CA,
  LOCALE_FR_CA,
  LocaleType,
} from '@glass/env/modules/locales/constants';

export type LocalePricingDefinition = {
  pricingDefinitions: PricingDefinitionType[];
};

type LocalePricingDefinitionsMapping = {
  [key in LocaleType]: LocalePricingDefinition;
};

const localePricingDefinitions: LocalePricingDefinitionsMapping = {
  [LOCALE_EN]: {
    pricingDefinitions: pricingDefinitionEnUs,
  },
  [LOCALE_EN_US]: {
    pricingDefinitions: pricingDefinitionEnUs,
  },
  [LOCALE_EN_CA]: {
    pricingDefinitions: pricingDefinitionEnCa,
  },
  [LOCALE_FR_CA]: {
    pricingDefinitions: pricingDefinitionFrCa,
  },
};

export default localePricingDefinitions;
