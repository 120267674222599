import generateAssignmentVariations from '@glass/common/modules/payments/generateAssignmentVariations';
import pricingDefinition from '@glass/common/modules/payments/prices/en-ca/pricingDefinition';

export const variations = generateAssignmentVariations(pricingDefinition);

export const PRICING_EN_CA = 'PRICING_EN_CA';
export default {
  name: PRICING_EN_CA,
  variations,
};
