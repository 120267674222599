const { currentOrigin } = require('@glass/env/modules/origins/constants');
const { LOCALE_EN, LOCALE_EN_CA, LOCALE_FR_CA } = require('@glass/env/modules/locales/constants');
const makeOriginLocale = require('@glass/env/modules/locales/makeOriginLocale').default;

/** @type {import('@glass/env/modules/locales/constants').LocaleType[]} */
const ACTIVE_LOCALES = [LOCALE_EN, LOCALE_EN_CA, LOCALE_FR_CA];

const defaultLocale = LOCALE_EN;

const rockDomain =
  process.env.NODE_ENV === 'development' ? 'rocket-resume.com' : new URL(currentOrigin).hostname;
const canadaDomain =
  process.env.NODE_ENV === 'development'
    ? 'rocket-resume.ca'
    : new URL(makeOriginLocale(currentOrigin, LOCALE_EN_CA)).hostname;

/** @type {import('next/dist/server/config-shared').I18NConfig} */
const i18nConfig = {
  locales: ACTIVE_LOCALES,
  defaultLocale,
  localeDetection: false,
  domains: [
    {
      domain: rockDomain,
      defaultLocale: LOCALE_EN,
    },
    {
      domain: canadaDomain,
      defaultLocale: LOCALE_EN_CA,
      locales: [LOCALE_FR_CA],
    },
  ],
};

module.exports = { ACTIVE_LOCALES, defaultLocale, i18nConfig };
