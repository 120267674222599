import isValue from '@glass/common/modules/utils/isValue';

type NonNullableProperties<T> = {
  [K in keyof T]: T[K] extends null | undefined | '' ? never : K;
}[keyof T];

type CleanedObject<T> = Pick<T, NonNullableProperties<T>>;

function removeEmptyKeys<T extends object>(obj: T) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => isValue(value)),
  ) as CleanedObject<T>;
}

export default removeEmptyKeys;
