export const SET_USER = 'SET_USER';
export const SIGN_OUT = 'SIGN_OUT';

export const AUTH_REDUCER_KEY = 'auth';

const initialState = {
  user: null,
  token: null,
};

export default function auth(state = initialState, action: any) {
  switch (action.type) {
    case SET_USER:
      return {
        user: action.payload.user,
        token: action.payload.token,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}

export const selectUser = (state: any) => state?.[AUTH_REDUCER_KEY]?.user;
export const selectUserId = (state: any) => state?.[AUTH_REDUCER_KEY]?.user?.id;
export const selectUserProfile = (state: any) => selectUser(state)?.profile;
export const selectIsUser = (state: any) => !!selectUser(state);
