const regex = /( |^).?/g;

const startCaseSpecial = (value: unknown) => {
  if (typeof value !== 'string') {
    return value;
  }
  const validValue = value.toLocaleLowerCase();
  if (validValue.length <= 3) {
    return value;
  }
  return value.toLowerCase().replace(regex, (match) => match.toUpperCase());
};

export default startCaseSpecial;
