import createPurchaseId from '@glass/common/modules/payments/createPurchaseId';
import { PricingDefinitionType, PricingPlanType } from '@glass/common/modules/payments/types';

const findPricingPlan = ({
  pricingDefinitions,
  key,
  purchaseId,
}: {
  pricingDefinitions: PricingDefinitionType[];
  key?: string;
  purchaseId?: string;
}) => {
  let foundPlan: PricingPlanType | null | undefined;

  if (!key && !purchaseId) {
    throw new Error('Either key or purchaseId must be provided');
  }

  const pricingDefinition = pricingDefinitions.find((pricingDef) => {
    // search by key
    if (key) {
      foundPlan = pricingDef.plans.find((plan) => plan.key === key);
      return !!foundPlan;
    }

    foundPlan = pricingDef.plans.find(
      (plan) => purchaseId === createPurchaseId(pricingDef.currency, plan),
    );

    return !!foundPlan;
  });

  if (!foundPlan || !pricingDefinition) {
    throw new Error(`No plan for purchaseId: ${purchaseId} key: ${key}`);
  }

  return {
    pricingDefinition,
    plan: foundPlan,
  };
};

export default findPricingPlan;
