import builderImprovements from '@glass/common/modules/assignments/configs/builderImprovements';
import cancel from '@glass/common/modules/assignments/configs/cancel';
import checkoutScroll from '@glass/common/modules/assignments/configs/checkoutScroll';
import cheetahSelection from '@glass/common/modules/assignments/configs/cheetahSelection';
import disableBuilderQueryCheck from '@glass/common/modules/assignments/configs/disableBuilderQueryCheck';
import improvementsDialog from '@glass/common/modules/assignments/configs/improvementsDialog';
import pricingEnCa from '@glass/common/modules/assignments/configs/pricingEnCa';
import pricingEnUS from '@glass/common/modules/assignments/configs/pricingEnUS';
import pricingFrCa from '@glass/common/modules/assignments/configs/pricingFrCa';
import qaOrigin from '@glass/common/modules/assignments/configs/qaOrigin';
import resumeDownloadUrlVersion from '@glass/common/modules/assignments/configs/resumeDownloadUrlVersion';
import stripe3DS from '@glass/common/modules/assignments/configs/stripe3DS';
import stripeBlockPrepaid from '@glass/common/modules/assignments/configs/stripeBlockPrepaid';
import subscriptionDowngrades from '@glass/common/modules/assignments/configs/subscriptionDowngrades';
import taxonomyClient from '@glass/common/modules/assignments/configs/taxonomyClient';
import createAssignmentConfigs from '@glass/common/modules/assignments/createAssignmentConfigs';
import { AssignmentSetting } from '@glass/common/modules/assignments/types';

export const userAssignments: AssignmentSetting[] = [
  pricingEnUS,
  pricingEnCa,
  pricingFrCa,
  subscriptionDowngrades,
  cancel,
  cheetahSelection,
  resumeDownloadUrlVersion,
  qaOrigin,
  disableBuilderQueryCheck,
  builderImprovements,
  taxonomyClient,
  improvementsDialog,
  stripe3DS,
  stripeBlockPrepaid,
  checkoutScroll,
];

const configs = createAssignmentConfigs(userAssignments);

export default configs;
