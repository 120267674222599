export const INTERNAL_QUERY = '_i';
export const RESUME_ID_QUERY = 'resumeId';
export const BASE_PATH_1_QUERY = 'basePath1';
export const BASE_PATH_2_QUERY = 'basePath2';
export const BASE_PATH_3_QUERY = 'basePath3';
export const BUILDER_STEPS_QUERY = 'builderSteps';
export const USER_AUTH_QUERY = 'e_id';

export const SEARCH_QUERY = 'search';
export const ASSIGNMENT_OVERRIDES_QUERY = '_ao';
export const NEXT_QUERY = 'next';
export const FAIL_QUERY = 'fail';

export const EMAIL_AUTH_QUERY = 'sa';
export const KLAVIYO_EMAIL_AUTH_QUERY = 'kat';
export const KLAVIYO_IDENTIFIER_QUERY = '_kx';
export const TEMPLATE_QUERY = 'template';
export const INDUSTRY_SLUG_QUERY = 'islug';
export const JOBTITLE_SLUG_QUERY = 'jslug';
export const SEED_QUERY = 'seed';
export const RESUME_TYPE_QUERY = 'resume-type';
export const MESSAGE_QUERY = 'message';
export const TAB_QUERY = 'tab';
export const NEXT_SECTIONS_QUERY = 'ns';
export const EDIT_INDEX_QUERY = 'eIdx';
export const PURCHASE_ID_QUERY = 'pid';
export const SUBSCRIPTION_ID_QUERY = 'subId';
export const STRIPE_RETURN_QUERY = 'sr';
export const STRIPE_PAYMENT_INTENT_QUERY = 'payment_intent';
export const STRIPE_PAYMENT_INTENT_CLIENT_SECRET_QUERY = 'payment_intent_client_secret';
export const STRIPE_REDIRECT_STATUS_QUERY = 'redirect_status';
export const STRIPE_SETUP_INTENT_QUERY = 'setup_intent';
export const PRESENTATION_QUERY = 'presentation';
export const PRICING_PACKAGE_QUERY = 'pa';
export const SNACK_BAR_QUERY = 'sb';
export const RELOAD_REASON_QUERY = 'reload';
export const WORK_EXP_QUERY = 'work-exp';
export const TEMPLATE_GROUP_QUERY = 'template-group';
export const EDUCATION_EXP_QUERY = 'education-exp';
export const TEMPLATE_VIEW_QUERY = 'tv';
export const FACEBOOK_CLICK_ID_QUERY = 'fbclid';

export type PageParam =
  | typeof BASE_PATH_1_QUERY
  | typeof BASE_PATH_2_QUERY
  | typeof BASE_PATH_3_QUERY
  | typeof BUILDER_STEPS_QUERY;

export type QueryParam =
  | typeof SEARCH_QUERY
  | typeof NEXT_QUERY
  | typeof FAIL_QUERY
  | typeof INTERNAL_QUERY
  | typeof USER_AUTH_QUERY
  | typeof EMAIL_AUTH_QUERY
  | typeof KLAVIYO_EMAIL_AUTH_QUERY
  | typeof KLAVIYO_IDENTIFIER_QUERY
  | typeof TEMPLATE_QUERY
  | typeof INDUSTRY_SLUG_QUERY
  | typeof JOBTITLE_SLUG_QUERY
  | typeof SEED_QUERY
  | typeof RESUME_ID_QUERY
  | typeof RESUME_TYPE_QUERY
  | typeof MESSAGE_QUERY
  | typeof NEXT_SECTIONS_QUERY
  | typeof EDIT_INDEX_QUERY
  | typeof PURCHASE_ID_QUERY
  | typeof SUBSCRIPTION_ID_QUERY
  | typeof PRESENTATION_QUERY
  | typeof PRICING_PACKAGE_QUERY
  | typeof SNACK_BAR_QUERY
  | typeof WORK_EXP_QUERY
  | typeof TEMPLATE_GROUP_QUERY
  | typeof EDUCATION_EXP_QUERY
  | typeof TEMPLATE_VIEW_QUERY
  | typeof FACEBOOK_CLICK_ID_QUERY
  | typeof TAB_QUERY;
