const createListSentence = (listProp: unknown[], { conjunction = 'and' } = {}) => {
  const list = listProp.filter((str) => typeof str === 'string' && str);
  if (!conjunction || list.length < 2) {
    return list.join(', ');
  }
  const last = list[list.length - 1];
  const allButLast = list.slice(0, -1);
  return [allButLast.join(', '), conjunction, last].join(' ');
};

export default createListSentence;
