import findPricingPlan from '@glass/common/modules/payments/findPricingPlan';
import parsePurchaseId from '@glass/common/modules/payments/parsePurchaseId';
import localePricingDefinitions from '@glass/common/modules/payments/prices/localePricingDefinitions';
import { PricingDefinitionType, PricingPlanType } from '@glass/common/modules/payments/types';
import { LocaleType } from '@glass/env/modules/locales/constants';

export const getInheritedPlan = (
  plan: PricingPlanType,
  pricingDefinitions: PricingDefinitionType[],
) => {
  if (!plan.inheritKey) {
    return plan;
  }

  const { plan: inheritedPlan } = findPricingPlan({ key: plan.inheritKey, pricingDefinitions });

  if (!inheritedPlan) {
    throw new Error(`Inherited plan not found: ${plan.inheritKey}`);
  }

  return { ...inheritedPlan, ...plan } as PricingPlanType;
};

const getPricingPlan = (locale: LocaleType, purchaseId: string) => {
  if (!localePricingDefinitions[locale]) {
    throw new Error(`Invalid locale: ${locale}`);
  }

  const { pricingDefinitions } = localePricingDefinitions[locale];
  const { plan, pricingDefinition } = findPricingPlan({ purchaseId, pricingDefinitions });
  const inheritedPlan = getInheritedPlan(plan, pricingDefinitions);

  const { currency } = parsePurchaseId(purchaseId);
  if (pricingDefinition.currency !== currency) {
    throw new Error(`Currency mismatch: ${currency} !== ${pricingDefinition.currency}`);
  }

  // todo: double check parsed values with the plan
  return {
    pricingDefinition,
    plan: inheritedPlan,
  };
};

export default getPricingPlan;
