import {
  TRIM_WHITESPACES,
  START_CASE_SPECIAL,
  SORT_ITEMS,
  LONG_VERSION,
} from '@glass/web/modules/resumes/improvements';

export const BUILDER_IMPROVEMENTS = 'BUILDER_IMPROVEMENTS';
// We are testing the new improvement by adding 2 variations, one with the new improvement and the other variation holds all the improvements except the new one
export default {
  name: BUILDER_IMPROVEMENTS,
  version: 6,
  variations: [
    {
      value: [TRIM_WHITESPACES, START_CASE_SPECIAL],
    },
    {
      value: [TRIM_WHITESPACES, START_CASE_SPECIAL, SORT_ITEMS],
    },
    {
      value: [TRIM_WHITESPACES, START_CASE_SPECIAL, LONG_VERSION],
    },
    {
      value: [TRIM_WHITESPACES, START_CASE_SPECIAL, SORT_ITEMS, LONG_VERSION],
    },
  ],
};
