import type { SetOption } from 'cookies';

import {
  ONE_DAY_SEC,
  ONE_MINUTE_SEC,
  ONE_MONTH_SEC,
  ONE_WEEK_SEC,
  ONE_YEAR_SEC,
} from '@glass/common/modules/dates/constants';
import { DEFAULT_LOCALE, LocaleType } from '@glass/env/modules/locales/constants';
import makeOriginLocale from '@glass/env/modules/locales/makeOriginLocale';
import { currentOrigin } from '@glass/env/modules/origins/constants';
import {
  ASSIGNMENT_COOKIE_NAME,
  AUTH_COOKIE_NAME,
  DEVICE_COOKIE_NAME,
  GOOGLE_CLICK_COOKIE_NAME,
  GOOGLE_DISPLAY_COOKIE_NAME,
  INTERNAL_COOKIE_NAME,
  PACKAGES_COOKIE_NAME,
  EMAIL_AUTH_COOKIE_NAME,
  SESSION_COOKIE_NAME,
  UTM_COOKIE_NAME,
  SUBSCRIPTION_TRACKING_COOKIE_NAME,
} from '@glass/web/modules/cookies/names';

export type CookieConfigs = SetOption & {
  isJson?: boolean;
  sameSite?: 'lax' | 'strict' | 'none';
};

export const commonAttrs: CookieConfigs = {
  path: '/',
  secure: process.env.NODE_ENV === 'production',
  domain: new URL(currentOrigin)?.hostname,
  sameSite: 'lax',
  httpOnly: false,
};

/**
 * Compute common cookie attributes based on a locale
 * @param obj
 * @returns
 */
export const computeCommonCookieAttributes = ({
  locale = DEFAULT_LOCALE,
}: {
  locale?: LocaleType;
}): CookieConfigs => ({
  ...commonAttrs,
  domain: new URL(makeOriginLocale(currentOrigin, locale)).hostname,
});

const SESSION_AGE = 30 * ONE_MINUTE_SEC;
const AUTH_AGE = 2 * ONE_WEEK_SEC;
const MAX_AGE = 2 * ONE_YEAR_SEC;
const ATTRIBUTION_AGE = 90 * ONE_DAY_SEC;

// todo: move setting cookies on server whenever possible, only a few exceptions
const cookieConfigs: Record<string, CookieConfigs> = {
  [INTERNAL_COOKIE_NAME]: {
    maxAge: ONE_MONTH_SEC,
  },
  [SESSION_COOKIE_NAME]: {
    maxAge: SESSION_AGE,
  },
  [GOOGLE_CLICK_COOKIE_NAME]: {
    maxAge: ATTRIBUTION_AGE,
  },
  [GOOGLE_DISPLAY_COOKIE_NAME]: {
    maxAge: ATTRIBUTION_AGE,
  },
  [UTM_COOKIE_NAME]: {
    maxAge: ATTRIBUTION_AGE,
  },
  [DEVICE_COOKIE_NAME]: {
    maxAge: MAX_AGE,
  },
  [ASSIGNMENT_COOKIE_NAME]: {
    maxAge: MAX_AGE,
  },
  [AUTH_COOKIE_NAME]: {
    maxAge: AUTH_AGE,
  },
  [EMAIL_AUTH_COOKIE_NAME]: {
    maxAge: AUTH_AGE,
  },
  [PACKAGES_COOKIE_NAME]: {
    maxAge: AUTH_AGE,
    isJson: true,
  },
  [SUBSCRIPTION_TRACKING_COOKIE_NAME]: {
    maxAge: MAX_AGE,
    isJson: true,
  },
};

export default cookieConfigs;
