import { BASE_PATH_2_QUERY, BASE_PATH_3_QUERY } from '@glass/common/modules/pages/queryParams';
import capitalizeFirstLetter from '@glass/common/modules/utils/capitalizeFirstLetter';
import createListSentence from '@glass/common/modules/utils/createListSentence';
import longVersionGeo from '@glass/common/modules/utils/longVersionGeo';
import removeUnnecessaryWhiteSpace from '@glass/common/modules/utils/removeUnnecessaryWhiteSpace';
import sortResumeFields from '@glass/common/modules/utils/sortResumeFields';
import startCaseSpecial from '@glass/common/modules/utils/startCaseSpecial';
import { ImprovementField } from '@glass/web/components/ResumeBuilder/Improvements/ImprovementFields';
import { EDUCATION_FIELD_V2, EXPERIENCES_FIELD_V2 } from '@glass/web/modules/resumes/fieldKeys';
import generateSectionStepKey from '@glass/web/modules/resumes/router/generateSectionStepKey';
import {
  CONTACT_SECTION,
  EDUCATION_SECTION,
  EXPERIENCE_SECTION,
} from '@glass/web/modules/resumes/router/sections';
import { SUMMARY_STEP } from '@glass/web/modules/resumes/steps';

export const TRIM_WHITESPACES = 'TRIM_WHITESPACES';
export const START_CASE = 'START_CASE';
export const START_CASE_SPECIAL = 'START_CASE_SPECIAL';
export const SORT_ITEMS = 'SORT_ITEMS';
export const LONG_VERSION = 'LONG_VERSION';

export type ImprovementsTypes =
  | typeof TRIM_WHITESPACES
  | typeof START_CASE
  | typeof START_CASE_SPECIAL;

export const SECTION_IMPROVEMENTS = {
  [CONTACT_SECTION]: [
    {
      key: 'contact.firstName',
      label: 'First Name',
      improvements: [TRIM_WHITESPACES, START_CASE_SPECIAL],
    },
    {
      key: 'contact.lastName',
      label: 'Last Name',
      improvements: [TRIM_WHITESPACES, START_CASE_SPECIAL],
    },
    {
      key: 'contact.location.city',
      label: 'City',
      improvements: [TRIM_WHITESPACES, START_CASE_SPECIAL],
    },
    {
      key: 'contact.location.state',
      label: 'State',
      improvements: [TRIM_WHITESPACES, START_CASE_SPECIAL, LONG_VERSION],
    },
    {
      key: 'contact.location.country',
      label: 'Country',
      improvements: [TRIM_WHITESPACES, START_CASE_SPECIAL, LONG_VERSION],
    },
    {
      key: 'contact.location.street',
      label: 'Street Address',
      improvements: [TRIM_WHITESPACES, START_CASE_SPECIAL],
    },
  ],
  [generateSectionStepKey({
    [BASE_PATH_2_QUERY]: EXPERIENCE_SECTION,
    [BASE_PATH_3_QUERY]: SUMMARY_STEP,
  })]: [{ key: EXPERIENCES_FIELD_V2, label: 'Work History', improvements: [SORT_ITEMS] }],
  [generateSectionStepKey({
    [BASE_PATH_2_QUERY]: EDUCATION_SECTION,
    [BASE_PATH_3_QUERY]: SUMMARY_STEP,
  })]: [{ key: EDUCATION_FIELD_V2, label: 'Education', improvements: [SORT_ITEMS] }],
};

export type Improvements = {
  [improvementName: string]: {
    title: string;
    createItemDescription: ({ field }: { field: ImprovementField }) => string;
    createSecondaryText: ({
      improvementFields,
    }: {
      improvementFields: Array<ImprovementField>;
    }) => string;
    performance: Function;
  };
};

const defaultDescription = ({ field }: { field: ImprovementField }) =>
  `<span>Change ${field.label?.toLowerCase() || ''} from <b>&quot;${
    field.value
  }&quot;</b> to <b>&quot;${
    typeof field.improved === 'string' ? field.improved : ''
  }&quot;</b></span>`;

const defaultSecondaryText = ({
  improvementFields,
}: {
  improvementFields: Array<ImprovementField>;
}) => {
  if (improvementFields.length > 4) {
    return `${improvementFields.length} fields affected`;
  }
  const labels = improvementFields.map((field) => field.label);
  return capitalizeFirstLetter(createListSentence(labels).toLocaleLowerCase());
};

const IMPROVEMENTS: Improvements = {
  [TRIM_WHITESPACES]: {
    title: 'Remove unnecessary white space',
    createItemDescription: defaultDescription,
    createSecondaryText: defaultSecondaryText,
    performance: (value: unknown) =>
      typeof value === 'string' ? removeUnnecessaryWhiteSpace(value) : value,
  },
  [START_CASE_SPECIAL]: {
    title: 'Use preferred text casing',
    createItemDescription: defaultDescription,
    createSecondaryText: defaultSecondaryText,
    performance: startCaseSpecial,
  },
  [SORT_ITEMS]: {
    title: 'Sort by most recent', // todo: add field ie work-history or education
    createItemDescription: () => `Sort items`,
    createSecondaryText: defaultSecondaryText,
    performance: sortResumeFields,
  },
  [LONG_VERSION]: {
    title: 'Use full words over abbreviations',
    createItemDescription: defaultDescription,
    createSecondaryText: defaultSecondaryText,
    performance: longVersionGeo,
  },
};

export default IMPROVEMENTS;
