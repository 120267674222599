import generateAssignmentVariations from '@glass/common/modules/payments/generateAssignmentVariations';
import pricingDefinition from '@glass/common/modules/payments/prices/en-us/pricingDefinition';

export const variations = generateAssignmentVariations(pricingDefinition);
export const PRICING_EN_US = 'PRICING_EN_US';

export default {
  name: PRICING_EN_US,
  variations,
};
