/* eslint-disable class-methods-use-this */
import JsCookies, { CookieAttributes } from 'js-cookie';

import { ONE_SEC_MS } from '@glass/common/modules/dates/constants';
import { DEFAULT_LOCALE, LocaleType } from '@glass/env/modules/locales/constants';
import cookieConfigs, {
  computeCommonCookieAttributes,
  CookieConfigs,
} from '@glass/web/modules/cookies/cookieConfigs';
import isValidCookieName from '@glass/web/modules/cookies/isValidCookieName';
import { ICookies, SetType } from '@glass/web/modules/cookies/types';

const options: Record<string, CookieAttributes> = {};
const settings: Record<string, CookieConfigs> = {};

export default class Cookies implements ICookies {
  public locale: LocaleType;

  constructor({ locale }: { locale?: LocaleType }) {
    this.locale = locale || DEFAULT_LOCALE;
  }

  static getCookieSettings(name: string, { locale }: { locale: LocaleType }) {
    if (!settings[name]) {
      settings[name] = {
        ...computeCommonCookieAttributes({ locale }),
        ...cookieConfigs[name],
      } as CookieConfigs;
    }
    return settings[name];
  }

  static getCookieOptions(name: string, { locale }: { locale: LocaleType }) {
    if (!options[name]) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { maxAge, path, secure, domain, sameSite, httpOnly } = Cookies.getCookieSettings(name, {
        locale,
      });

      const cookieOptions: CookieAttributes = {
        path,
        secure,
        domain,
        httpOnly,
        sameSite,
      };
      if (typeof maxAge !== 'undefined') {
        cookieOptions.expires = new Date(maxAge * ONE_SEC_MS + Date.now());
      }
      options[name] = cookieOptions;
    }
    return options[name];
  }

  get(name: string) {
    const value = JsCookies.get(name);
    if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
      console.info(
        'cookies get',
        name,
        value,
        Cookies.getCookieOptions(name, { locale: this.locale }),
      );
    }
    if (!value) {
      return '';
    }
    const { isJson } = Cookies.getCookieSettings(name, { locale: this.locale });
    if (isJson) {
      try {
        return JSON.parse(decodeURIComponent(value));
      } catch (err) {
        this.remove(name);
        return '';
      }
    }

    return value;
  }

  remove(name: string) {
    if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
      console.info('cookies remove', name, Cookies.getCookieOptions(name, { locale: this.locale }));
    }
    JsCookies.remove(name, Cookies.getCookieOptions(name, { locale: this.locale }));
    return;
  }

  set(name: string, value: SetType) {
    if (!isValidCookieName(name, cookieConfigs)) {
      throw new Error(`invalid cookie ${name}`);
    }

    let setValue = typeof value === 'function' ? value() : value;

    const { isJson } = Cookies.getCookieSettings(name, { locale: this.locale });
    if (isJson) {
      setValue = JSON.stringify(setValue);
    }
    if (process.env.NEXT_PUBLIC_DEBUG === 'cookies') {
      console.info(
        'cookies set',
        name,
        setValue,
        Cookies.getCookieOptions(name, { locale: this.locale }),
      );
    }

    if (typeof setValue !== 'string') {
      throw new Error(`invalid cookie value ${setValue}`);
    }

    JsCookies.set(name, setValue, Cookies.getCookieOptions(name, { locale: this.locale }));
    return setValue;
  }

  upsert(name: string, defaultValue: string) {
    return this.get(name) || this.set(name, defaultValue);
  }
}
