import type { I18NConfig } from 'next/dist/server/config-shared';

import type { LocaleType } from '@glass/env/modules/locales/constants';

function joinPaths(path1: string, path2: string) {
  const trimmedPath1 = path1.replace(/\/+$/, ''); // Remove trailing slashes from path1
  const trimmedPath2 = path2.replace(/^\/+/, ''); // Remove leading slashes from path2

  return `${trimmedPath1}/${trimmedPath2}`;
}

/**
 * Gets the locale path to append into an origin based on a given locale.
 *
 * @param path
 * @param locale
 * @returns
 */
const getLocalePath = (i18nConfig?: I18NConfig, path = '', locale?: LocaleType) => {
  if (!locale || !i18nConfig) {
    return path;
  }

  const domainForLocale = i18nConfig.domains?.find(
    (e) => e.defaultLocale === locale || e.locales?.includes(locale),
  );

  if (!domainForLocale || domainForLocale.defaultLocale === locale) return path;
  return `/${joinPaths(locale, path)}`;
};

export default getLocalePath;
