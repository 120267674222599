import countries, {
  Country,
  UNITED_STATES_COUNTRY_CODE,
} from '@glass/common/modules/geo/countries';
import findCountry from '@glass/common/modules/geo/findCountry';
import usStates, { State } from '@glass/common/modules/geo/usStates';

export const stateCache: Record<string, State | null> = {};

const createCacheKey = (stateSearch: string, country: Country) => `${stateSearch}.${country.code}`;

const cacheResult = (stateSearch: string, country: Country, value: State | null) => {
  const key = createCacheKey(stateSearch, country);
  if (typeof stateCache[key] === 'undefined') {
    stateCache[key] = value ?? null;
  }
  return stateCache[key];
};

const standardizeSearch = (search: string) => search.toUpperCase().trim();

// DEPRECATED - need to remove countries and states dependency from client - they should be fetched asynchronously
const findState = (stateSearch: unknown, countrySearch: unknown = UNITED_STATES_COUNTRY_CODE) => {
  if (typeof stateSearch !== 'string' || !stateSearch || !countrySearch) {
    return null;
  }

  const country = findCountry(countrySearch, countries);

  if (!country) {
    return null;
  }

  const standardStateSearch = standardizeSearch(stateSearch);
  const key = createCacheKey(standardStateSearch, country);

  if (typeof stateCache?.[key] !== 'undefined') {
    return stateCache[key];
  }

  if (standardStateSearch?.length < 2) {
    return cacheResult(standardStateSearch, country, null);
  }

  return (
    usStates.find((state) => {
      const { label, code } = state;
      const upperLabel = standardizeSearch(label);
      cacheResult(upperLabel, country, state);
      cacheResult(code, country, state);
      return upperLabel === standardStateSearch || code === standardStateSearch;
    }) || null
  );
};

export default findState;
