import { ALL_CURRENCIES } from '@glass/common/modules/payments/currencies';
import {
  INTERVAL_DAY_SUFFIX,
  INTERVAL_MONTH_SUFFIX,
  INTERVAL_WEEK_SUFFIX,
  INTERVAL_YEAR_SUFFIX,
} from '@glass/common/modules/payments/prices/pricingIntervals';
import {
  INITIAL_COST_PREFIX,
  RECURRING_COST_PREFIX,
  TRIAL_DAYS_PREFIX,
} from '@glass/common/modules/payments/prices/purchaseIdPrefixes';

const currencyRegex = ALL_CURRENCIES.join('|');

const purchaseIdPartsRegex = new RegExp(
  `^(${currencyRegex})\\.${TRIAL_DAYS_PREFIX}(\\d+)\\.${INITIAL_COST_PREFIX}(\\d+)\\.${RECURRING_COST_PREFIX}(\\d+)\\.(\\d+)?([${INTERVAL_YEAR_SUFFIX}${INTERVAL_MONTH_SUFFIX}${INTERVAL_WEEK_SUFFIX}${INTERVAL_DAY_SUFFIX}])$`,
);

const parsePurchaseId = (purchaseId: string) => {
  const match = purchaseId.match(purchaseIdPartsRegex);

  // Format validation
  if (!match) {
    throw new Error(`Invalid purchaseId: ${purchaseId}`);
  }

  const [, currency] = match;

  return {
    // todo: evenutally fill in the rest even though its unused
    currency,
  };
};

export default parsePurchaseId;
