import { USD } from '@glass/common/modules/payments/currencies';
import { WEEK, YEAR } from '@glass/common/modules/payments/prices/pricingIntervals';
import { PricingDefinitionType } from '@glass/common/modules/payments/types';

const pricingDefinition: PricingDefinitionType[] = [
  {
    assignmentWeight: 1,
    currency: USD,
    plans: [
      {
        initialAmount: 195,
        recurringAmount: 3495,
        trialPeriodDays: 14,
        interval: WEEK,
        intervalCount: 4,
      },
      {
        initialAmount: 595,
        recurringAmount: 7140,
        trialPeriodDays: 30,
        interval: YEAR,
        intervalCount: 1,
      },
    ],
  },
];

export default pricingDefinition;
