import {
  END_DATE_UTC_FIELD_V2,
  EXPERIENCES_FIELD_V2,
  START_DATE_UTC_FIELD_V2,
} from '@glass/web/modules/resumes/fieldKeys';

type Item = {
  endDateUTC?: string;
  startDateUTC?: string;
};

const sortResumeFields = (items: Array<Item> | unknown, field: string): Array<Item> | unknown => {
  if (!Array.isArray(items)) {
    return items;
  }
  const sortField =
    field === EXPERIENCES_FIELD_V2 ? START_DATE_UTC_FIELD_V2 : END_DATE_UTC_FIELD_V2;
  return [...items].sort((a, b) => {
    if (!a[sortField] || !b[sortField]) {
      return 0;
    }

    return new Date(b[sortField]).getTime() - new Date(a[sortField]).getTime();
  }) as Array<Item>;
};

export default sortResumeFields;
