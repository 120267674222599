export const PURCHASE_CONTEXT = 'purchase';
export const CREATE_CUSTOMER_CONTEXT = 'create';

export type AssignmentVariationValue = string | number | string[] | number[];

export type AssignmentVariation =
  | {
      value: AssignmentVariationValue;
      weight?: number;
    }
  | AssignmentVariationValue;

type IndexObject = { value: number; weight: number };

type Index = number | IndexObject;

export type AssignmentIndexOrdered = {
  value: AssignmentVariationValue;
  indexes: Index[];
};

export type AssignmentContext = typeof PURCHASE_CONTEXT | typeof CREATE_CUSTOMER_CONTEXT;

export type AssignmentSetting = {
  name: string;
  version?: number;
  variations?: AssignmentVariation[];
  rollOut?: number;
  description?: string;
  indexedOrder?: AssignmentIndexOrdered[];
  value?: AssignmentVariationValue;
  contexts?: AssignmentContext[];
};

export type AssignmentConfig = AssignmentSetting & {
  name: string;
  version: number;
  variations: AssignmentVariation[];
  description?: string;
};

export type AssignmentOverrides = Record<string, AssignmentVariationValue>;
