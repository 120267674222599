import uniqBy from 'lodash/uniqBy';

import { ONE_SEC_MS } from '@glass/common/modules/dates/constants';
import getResumeExperienceTitles from '@glass/common/modules/resumes/getResumeExperienceTitles';
import parseDisplayName from '@glass/common/modules/resumes/parseDisplayName';
import getResumeContactCity from '@glass/common/modules/resumes/selectors/getResumeContactCity';
import getResumeContactCountry from '@glass/common/modules/resumes/selectors/getResumeContactCountry';
import getResumeContactEmail from '@glass/common/modules/resumes/selectors/getResumeContactEmail';
import getResumeContactFirstName from '@glass/common/modules/resumes/selectors/getResumeContactFirstName';
import getResumeContactLastName from '@glass/common/modules/resumes/selectors/getResumeContactLastName';
import getResumeContactPhone from '@glass/common/modules/resumes/selectors/getResumeContactPhone';
import getResumeContactStateCode from '@glass/common/modules/resumes/selectors/getResumeContactStateCode';
import getResumeContactZip from '@glass/common/modules/resumes/selectors/getResumeContactZip';
import {
  CREATED_AT,
  MOST_RECENT_RESUME_ID,
  STRIPE_CURRENCY,
  STRIPE_CUSTOMER_ID,
  STRIPE_SUBSCRIPTION_CANCEL_AT,
  STRIPE_SUBSCRIPTION_CANCEL_AT_PERIOD_END,
  STRIPE_SUBSCRIPTION_CANCELED_AT,
  STRIPE_SUBSCRIPTION_PLAN_AMOUNT,
  STRIPE_SUBSCRIPTION_PLAN_ID,
  STRIPE_SUBSCRIPTION_STATUS,
  STRIPE_TRIAL_END,
} from '@glass/common/modules/tracking/constants/propertyKeys';
import createSlug from '@glass/common/modules/url/createSlug';
import { standardizePhone } from '@glass/common/modules/utils/formatPhone';
import isValue from '@glass/common/modules/utils/isValue';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';

import {
  ADJECTIVE_CONTENT_KEY,
  CONTENT_CONTENT_KEY,
  RESUME_CONTENT_KEY,
  VERB_CONTENT_KEY,
} from '@glass/shared/modules/content/contentReducer';

export const isKlaviyoIdentifiable = ({ $id }) => !!$id;

const getUserProps = (userProp, trackingProps, resume, { identifyFromProps = false } = {}) => {
  const {
    standardizedPhone,
    email: emailProps,
    userId: userIdProps,
    stripeCustomer,
    stripeCustomerId,
    internal,
    assignmentId,
  } = trackingProps || {};

  let { firstName, lastName } = trackingProps || {};
  const user = userProp || resume?.user || null;
  let userId = user?.id || stripeCustomer?.metadata?.userId;

  if (!userId && identifyFromProps) {
    userId = userIdProps;
  }

  let email = user?.email || stripeCustomer?.email;

  if (!email && identifyFromProps) {
    email = emailProps;
  }

  const displayName =
    user?.profile?.displayName || trackingProps?.displayName || stripeCustomer?.name;

  if (!firstName && !lastName) {
    const profileName = parseDisplayName(displayName);
    firstName = profileName?.firstName;
    lastName = profileName?.lastName;
  }
  if (!firstName && !lastName && resume) {
    firstName = getResumeContactFirstName(resume);
    lastName = getResumeContactLastName(resume);
  }

  const phone =
    standardizePhone(user?.profile?.phone) ||
    standardizePhone(standardizedPhone) ||
    standardizePhone(getResumeContactPhone(resume)) ||
    standardizePhone(stripeCustomer?.phone);

  return {
    $id: userId,
    $email: email,
    $first_name: firstName,
    $last_name: lastName,
    $phone_number: phone,
    displayName,
    internal: internal ? true : null,
    assignmentId,
    [STRIPE_CUSTOMER_ID]: user?.stripeCustomerId || stripeCustomerId || stripeCustomer?.id,
    [CREATED_AT]: user?.createdAt,
  };
};

const getLocationProps = (user, trackingProps, resume) => {
  const { city, state, country, zip } = user?.profile || {};
  const {
    city: cityTracking,
    stateCode,
    countryCode,
    zipCode,
    stripeCustomer,
  } = trackingProps || {};

  const profileLocation = removeEmptyKeys({
    $city: city,
    $region: state,
    $country: country,
    $zip: zip,
  });

  const stripeLocation = removeEmptyKeys({
    $city: stripeCustomer?.address?.city,
    $region: stripeCustomer?.address?.state,
    $country: stripeCustomer?.address?.country,
    $zip: stripeCustomer?.address?.postal_code,
  });

  const trackingLocation = removeEmptyKeys({
    $city: cityTracking,
    $region: stateCode,
    $country: countryCode,
    $zip: zipCode,
  });

  const resumeLocation = removeEmptyKeys({
    $city: getResumeContactCity(resume),
    $region: getResumeContactStateCode(resume),
    $country: getResumeContactCountry(resume),
    $zip: getResumeContactZip(resume),
  });

  return [profileLocation, stripeLocation, trackingLocation, resumeLocation].reduce(
    (maxLocation, loc) => {
      if (!maxLocation || Object.keys(loc).length > Object.keys(maxLocation).length) {
        return loc;
      }
      return maxLocation;
    },
    null,
  );
};

const getStripeProps = (user, trackingProps) => {
  const { stripeCustomer: stripeCustomerProps, stripe, stripeSubscription } = trackingProps || {};
  const { object: objectType } = stripe?.object || {};

  const stripeCustomer = stripeCustomerProps || (objectType === 'customer' ? stripe?.object : null);
  const stripeCustomerId = stripeCustomer?.id;

  const subscription =
    stripeSubscription ||
    stripeCustomer?.subscriptions?.data?.[0] ||
    (objectType === 'subscription' ? stripe.object : null);

  const {
    status: stripeSubscriptionStatus,
    cancel_at_period_end: stripeSubscriptionCancelAtPeriodEnd,
    cancel_at: stripeSubscriptionCancelAt,
    canceled_at: stripeSubscriptionCanceledAt,
    plan: stripeSubscriptionPlan,
    trial_end: trialEnd,
  } = subscription || {};

  const { default_source: defaultSource, invoice_settings: invoiceSettings } = stripeCustomer || {};

  const {
    address_city: sourceCity,
    address_country: sourceCountry,
    address_state: sourceState,
    address_zip: sourceZip,
    address_zip_check: sourceZipCheck,
    country: cardCountry,
    cvc_check: sourceCvcCheck,
    brand: sourceBrand,
    exp_month: sourceExpMonth,
    exp_year: sourceExpYear,
    funding: sourceFunding,
  } = defaultSource || invoiceSettings?.default_payment_method?.card || {};

  return {
    sourceCity,
    sourceCountry,
    sourceState,
    sourceZip,
    sourceZipCheck,
    sourceCvcCheck,
    sourceBrand,
    sourceExpMonth,
    sourceExpYear,
    sourceFunding,
    cardCountry,
    [STRIPE_CUSTOMER_ID]: stripeCustomerId,
    [STRIPE_SUBSCRIPTION_STATUS]: stripeSubscriptionStatus,
    [STRIPE_SUBSCRIPTION_CANCEL_AT_PERIOD_END]: stripeSubscriptionCancelAtPeriodEnd,
    [STRIPE_SUBSCRIPTION_PLAN_ID]: stripeSubscriptionPlan?.id,
    [STRIPE_SUBSCRIPTION_PLAN_AMOUNT]: stripeSubscriptionPlan?.amount,
    [STRIPE_CURRENCY]: stripeSubscriptionPlan?.currency,
    [STRIPE_TRIAL_END]: trialEnd ? new Date(trialEnd * ONE_SEC_MS) : undefined,
    [STRIPE_SUBSCRIPTION_CANCELED_AT]: stripeSubscriptionCanceledAt
      ? new Date(stripeSubscriptionCanceledAt * ONE_SEC_MS)
      : undefined,
    [STRIPE_SUBSCRIPTION_CANCEL_AT]: stripeSubscriptionCancelAt
      ? new Date(stripeSubscriptionCancelAt * ONE_SEC_MS)
      : undefined,
  };
};

const getResumeProps = (user, trackingProps, resumeProp) => {
  const resumes = user?.resumes || (resumeProp ? [resumeProp] : null);
  const {
    [MOST_RECENT_RESUME_ID]: mostRecentResumeId = resumes?.[0]?.id,
    [RESUME_CONTENT_KEY]: resumeWord,
    [ADJECTIVE_CONTENT_KEY]: adjectiveWord,
    [VERB_CONTENT_KEY]: verbWord,
    [CONTENT_CONTENT_KEY]: contentWord,
  } = trackingProps || {};

  let jobTitles = [];
  let jobTitleSlugs;
  let secondaryEmail;

  if (resumes?.length) {
    resumes.forEach((resume) => {
      const titles = getResumeExperienceTitles(resume);
      const resumeEmail = getResumeContactEmail(resume);
      if (!secondaryEmail && resumeEmail && resumeEmail !== user?.email) {
        secondaryEmail = resumeEmail;
      }
      if (titles?.length) {
        jobTitles = jobTitles.concat(titles);
      }
    });
    jobTitles = uniqBy(jobTitles.filter(isValue), createSlug);
    jobTitleSlugs = jobTitles.map(createSlug);
  }

  return {
    jobTitles: jobTitles?.length ? jobTitles : undefined,
    jobTitleSlugs: jobTitleSlugs?.length ? jobTitleSlugs : undefined,
    secondaryEmail,
    primaryJobTitle: jobTitles?.[0],
    [MOST_RECENT_RESUME_ID]: mostRecentResumeId,
    resumeWord,
    adjectiveWord,
    verbWord,
    contentWord,
  };
};

const getKlaviyoIdentifyProperties = (userProp, trackingProps, resume, options = {}) => {
  const userProps = getUserProps(userProp, trackingProps, resume, options);
  const locationProps = getLocationProps(userProp, trackingProps, resume, options);
  const stripeProps = getStripeProps(userProp, trackingProps, resume, options);
  const resumeProps = getResumeProps(userProp, trackingProps, resume, options);

  if (userProps?.$id && !userProps.$email) {
    throw new Error('klaviyo userid-email conflict');
  }

  return {
    ...userProps,
    ...locationProps,
    ...stripeProps,
    ...resumeProps,
  };
};

export default getKlaviyoIdentifyProperties;
