import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useTracking from '@glass/shared/modules/tracking/useTracking';

const PATH_REGEX = /\?.*$/;

export const useTrackingPath = (key, value = null) => {
  const tracking = useTracking();
  const { isReady, asPath } = useRouter();
  useEffect(() => {
    if (isReady && asPath) {
      const pathname = asPath.replace(PATH_REGEX, '');
      tracking.addPathProp(pathname, key, value);
    }
  }, [value, key, tracking, isReady, asPath]);
};

export const useTrackingDefault = (key, value = null) => {
  const tracking = useTracking();
  useEffect(() => {
    tracking.addDefaultProp(key, value);
  }, [value, key, tracking]);
};

export const useTrackingPathProps = (props) => {
  const tracking = useTracking();
  const { isReady, asPath } = useRouter();
  useEffect(() => {
    if (isReady && asPath && props) {
      const pathname = asPath.replace(PATH_REGEX, '');
      tracking.addPathProps(pathname, props);
    }
  }, [asPath, isReady, props, tracking]);
};
