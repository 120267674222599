import {
  LOCALE_EN,
  LOCALE_EN_US,
  LOCALE_EN_CA,
  LOCALE_FR_CA,
  LocaleType,
} from '@glass/env/modules/locales/constants';

const CANADA_ENDING = '.ca';

const LOCALE_ORIGIN_ENDING = {
  [LOCALE_EN_CA]: CANADA_ENDING,
  [LOCALE_FR_CA]: CANADA_ENDING,
};

/**
 * Creates a locale-specific origin from a given origin and locale.
 *
 * @param origin
 * @param locale
 * @returns
 */
const makeOriginLocale = (origin: string, locale?: LocaleType) => {
  if (!locale) {
    return origin;
  }

  switch (locale) {
    case LOCALE_EN_CA:
    case LOCALE_FR_CA:
      return origin.replace(/\.com$/, LOCALE_ORIGIN_ENDING[locale]);
    case LOCALE_EN_US:
    case LOCALE_EN:
      return origin;
    default:
      throw new Error(`invalid locale: ${locale || 'none'}`);
  }
};

export default makeOriginLocale;
