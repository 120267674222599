import { AssignmentVariation } from '@glass/common/modules/assignments/types';
import createPurchaseId from '@glass/common/modules/payments/createPurchaseId';
import { getInheritedPlan } from '@glass/common/modules/payments/getPricingPlan';
import { PricingDefinitionType } from '@glass/common/modules/payments/types';

const createPricingAssignmentVariations = (pricingDefinitions: PricingDefinitionType[]) =>
  pricingDefinitions.reduce((agg: AssignmentVariation[], definition) => {
    if (definition.assignmentWeight === undefined || !(definition.assignmentWeight > 0)) {
      return agg;
    }

    return [
      ...agg,
      {
        weight: definition.assignmentWeight,
        value: definition.plans.map((plan) => {
          const inheritedPlan = getInheritedPlan(plan, pricingDefinitions);
          return createPurchaseId(definition.currency, inheritedPlan);
        }),
      },
    ];
  }, []);

export default createPricingAssignmentVariations;
