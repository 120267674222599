import { persistStore as reduxPersistStore } from 'redux-persist';

const persistStore = (...args) => {
  if (__SERVER__) {
    return undefined;
  }
  return reduxPersistStore(...args);
};

export default persistStore;
