import { ONE_MINUTE_MS } from '@glass/common/modules/dates/constants';
import { selectIsUser } from '@glass/web/modules/auth';

import {
  selectHasResumesRehydrated,
  selectSaveProgress,
} from '@glass/shared/modules/resumes/resumesReducer';

export const SET_AUTH_PROPS = 'SET_AUTH_PROPS';
export const EXTEND_AUTH_PROPS = 'EXTEND_AUTH_PROPS';

export const AUTH_FORM_REDUCER_KEY = 'authForm';

const initialState = {
  children: null,
  view: null,
  actionType: null,
  toggleAuthWithinComponent: false,
  email: null,
  title: null,
  message: null,
  active: false,
};

export default function authForm(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_PROPS:
      return {
        ...initialState,
        ...action.payload,
      };
    case EXTEND_AUTH_PROPS:
      return {
        ...state,
        // properties to reset
        title: initialState.title,
        message: initialState.message,
        children: initialState.children,
        ...action.payload,
        active: true,
      };
    default:
      return state;
  }
}

export const selectAuthProps = (state) => state?.[AUTH_FORM_REDUCER_KEY];
export const selectAuthOnExit = (state) => state[AUTH_FORM_REDUCER_KEY].onExit;
export const selectAuthActive = (state) => selectAuthProps(state).active;

const TEN_MINUTES = 10 * ONE_MINUTE_MS;

export const selectSaveProgressEnabled = (state) => {
  const { lastPromptDate } = selectSaveProgress(state);

  return (
    !selectAuthActive(state) &&
    !selectIsUser(state) &&
    selectHasResumesRehydrated(state) &&
    !(Date.now() - new Date(lastPromptDate).getTime() < TEN_MINUTES)
  );
};
