import {
  CAPITALIZED_KEY,
  createPostFixWordFn,
  createStartCaseWordFn,
  createWordVariations,
  CreateWordVariationsOptions,
  DEFAULT_CONTENT_CREATE_FUNCTIONS,
  PLURAL_CAPITALIZED_KEY,
  PLURAL_KEY,
  SINGULAR_KEY,
  type VariationKey,
} from '@glass/common/modules/content/staticVariations/createWordVariations';

import postFixWord from '@glass/shared/helpers/postFixWord';

export const TEMPLATE_CONTENT_WORD = 'template';
export const TEMPLATES_CONTENT_WORD = postFixWord(TEMPLATE_CONTENT_WORD);
// export const CAPITALIZED_TEMPLATES_CONTENT_WORD = capitalizeFirstLetter(TEMPLATES_CONTENT_WORD);
export const EXAMPLE_CONTENT_WORD = 'example';
export const EXAMPLES_CONTENT_WORD = postFixWord(EXAMPLE_CONTENT_WORD);
export const SAMPLE_CONTENT_WORD = 'sample';
export const SAMPLES_CONTENT_WORD = postFixWord(SAMPLE_CONTENT_WORD);
export const IDEA_CONTENT_WORD = 'idea';
export const IDEAS_CONTENT_WORD = postFixWord(IDEA_CONTENT_WORD);
export const SUGGESTION_CONTENT_WORD = 'suggestion';
export const SUGGESTIONS_CONTENT_WORD = postFixWord(SUGGESTION_CONTENT_WORD);
export const SECTION_CONTENT_WORD = 'section';
export const SECTIONS_CONTENT_WORD = postFixWord(SECTION_CONTENT_WORD);
export const CONTENT_CONTENT_WORD = 'content';
export const COPY_CONTENT_WORD = 'copy';

export const CONTENT_WORD_KEYS: Array<VariationKey> = [
  CAPITALIZED_KEY,
  SINGULAR_KEY,
  PLURAL_KEY,
  PLURAL_CAPITALIZED_KEY,
];

const CONTENT_WORD_CREATE_FUNCTIONS = {
  ...DEFAULT_CONTENT_CREATE_FUNCTIONS,
  [PLURAL_KEY]: createPostFixWordFn(SINGULAR_KEY),
  [PLURAL_CAPITALIZED_KEY]: createStartCaseWordFn(PLURAL_KEY),
};

// todo: make the keys use the singular version
const defaults = {
  [TEMPLATES_CONTENT_WORD]: {
    [SINGULAR_KEY]: TEMPLATE_CONTENT_WORD,
  },
  [EXAMPLES_CONTENT_WORD]: {
    [SINGULAR_KEY]: EXAMPLE_CONTENT_WORD,
  },
  [SAMPLES_CONTENT_WORD]: {
    [SINGULAR_KEY]: SAMPLE_CONTENT_WORD,
  },
  [IDEAS_CONTENT_WORD]: {
    [SINGULAR_KEY]: IDEA_CONTENT_WORD,
  },
  [SUGGESTIONS_CONTENT_WORD]: {
    [SINGULAR_KEY]: SUGGESTION_CONTENT_WORD,
  },
  [CONTENT_CONTENT_WORD]: {
    [SINGULAR_KEY]: CONTENT_CONTENT_WORD,
  },
  [COPY_CONTENT_WORD]: {
    [SINGULAR_KEY]: COPY_CONTENT_WORD,
  },
  [SECTIONS_CONTENT_WORD]: {
    [SINGULAR_KEY]: SECTIONS_CONTENT_WORD,
  },
};

export const createContentWords = (variationKey: string, options: CreateWordVariationsOptions) =>
  variationKey
    ? createWordVariations(
        {
          [PLURAL_KEY]: variationKey,
          ...(defaults[variationKey] || {}),
        },
        CONTENT_WORD_KEYS,
        CONTENT_WORD_CREATE_FUNCTIONS,
        options,
      )
    : null;

export const DEFAULT_CONTENT_WORDS = createContentWords(EXAMPLES_CONTENT_WORD, {
  isBrowserSafe: true,
});

const contentWordVariations = [
  TEMPLATES_CONTENT_WORD,
  EXAMPLES_CONTENT_WORD,
  SAMPLES_CONTENT_WORD,
  IDEAS_CONTENT_WORD,
  SUGGESTIONS_CONTENT_WORD,
  SECTIONS_CONTENT_WORD,
  CONTENT_CONTENT_WORD,
  COPY_CONTENT_WORD,
];

export const weightedContentVariations = [
  { value: TEMPLATES_CONTENT_WORD, weight: 2 },
  { value: SAMPLES_CONTENT_WORD, weight: 3 },
  { value: EXAMPLES_CONTENT_WORD, weight: 4 },
];

export default contentWordVariations;
