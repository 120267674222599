import parseDisplayName from '@glass/common/modules/resumes/parseDisplayName';
import getResumeContactLastName from '@glass/common/modules/resumes/selectors/getResumeContactLastName';
import { selectUserProfile } from '@glass/web/modules/auth';
import { selectResumeValue } from '@glass/web/modules/resumes/selectors';

import selectPaymentFormDisplayName from '@glass/shared/modules/resumes/selectors/selectPaymentFormDisplayName';

const selectUserLastName = (state) => {
  const resume = selectResumeValue(state);
  const resumeLastName = getResumeContactLastName(resume);
  if (resumeLastName) {
    return resumeLastName;
  }
  const { displayName: paymentDisplayName } = selectPaymentFormDisplayName(state) || {};
  const paymentFormLastName = parseDisplayName(paymentDisplayName)?.lastName;

  if (paymentFormLastName) {
    return paymentFormLastName;
  }

  const { displayName } = selectUserProfile(state) || {};
  return parseDisplayName(displayName).lastName;
};

export default selectUserLastName;
