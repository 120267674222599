import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import filterObject from '@glass/common/modules/utils/filterObject';

import { useTrackingPathProps } from '@glass/shared/modules/tracking/useTrackingProp';

const withGlobalTracking = () => (Component) => {
  function WithGlobalTracking(props) {
    const {
      pageProps: { pageData = {}, trackingKeys = [] },
    } = props;

    const trackingData = useMemo(
      () => filterObject(pageData, trackingKeys)?.[0],
      [pageData, trackingKeys],
    );

    useTrackingPathProps(trackingData);

    return <Component {...props} />;
  }

  WithGlobalTracking.propTypes = {
    pageProps: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      pageData: PropTypes.object,
      trackingKeys: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  WithGlobalTracking.getInitialProps = Component.getInitialProps;
  return WithGlobalTracking;
};

export default withGlobalTracking;
