import type { AnyAction, Reducer } from 'redux';
import {
  persistReducer as reduxPersistReducer,
  PersistConfig,
  PersistedState,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const createMigrate =
  ({ version }: { version?: PersistConfig<any>['version'] }) =>
  async (state: PersistedState) => {
    const { _persist } = state || {};
    if (version && _persist?.version !== version) {
      return null as unknown as PersistedState;
    }
    return state;
  };

const persistReducer = <T>(config: Partial<PersistConfig<any>>, reducer: T): T => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (__SERVER__) {
    return reducer;
  }
  const { version, key, ...otherConfig } = config;

  if (!key) {
    throw new Error('A config key must be provided');
  }
  return reduxPersistReducer(
    {
      version,
      key,
      storage,
      debug: process.env.NODE_ENV !== 'production',
      migrate: createMigrate(config as PersistConfig<any>),
      ...otherConfig,
    },
    reducer as Reducer<any, AnyAction>,
  ) as T;
};

export default persistReducer;
