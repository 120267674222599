import {
  AssignmentConfig,
  AssignmentVariationValue,
} from '@glass/common/modules/assignments/types';
import { getValue } from '@glass/common/modules/utils/getArrayValueFromFloat';

const variationHash: Record<string, Record<string, boolean>> = {};

const isValidAssignmentVariation = (
  configs: AssignmentConfig[],
  assignmentName: string,
  variation: AssignmentVariationValue,
) => {
  if (Array.isArray(variation)) {
    return true;
  }

  if (typeof variationHash[assignmentName]?.[variation] !== 'undefined') {
    return variationHash[assignmentName][variation];
  }

  return configs.some(({ name, variations }) => {
    if (!variationHash[name]) {
      variationHash[name] = variations.reduce(
        (agg, configVariation) => ({
          ...agg,
          [getValue(configVariation) as string]: true,
        }),
        {},
      );
    }
    return assignmentName === name && variationHash[assignmentName][variation];
  });
};

export default isValidAssignmentVariation;
